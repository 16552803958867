import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class ErrorHandlerInterceptor implements HttpInterceptor {
  intercept(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {
      return next.handle(request)
          .pipe(catchError((error: HttpErrorResponse) => {
                if (error && error.error instanceof ErrorEvent) {
                  console.error('Client error.');
                } else if (error) {
                  console.error('Server error. Status: ', error);
                }
                return throwError(error);
            })
          )
  }
}