/*! *********************************************
 Author: Libu T.Babu, email: crazylibu@yahoo.com
*********************************************** */
import { Component, Input, ElementRef, ViewChildren, ViewChild, HostListener, OnChanges, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNotNullAndNotEmpty, clearTimer } from '@app/core';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements AfterViewInit, OnChanges {

    @Input() public links: any[] = [];
    @ViewChildren('myLink') public breadCrumbs: any;
    @ViewChild('breadcrumbContainer', {static: false}) public breadcrumbContainer: ElementRef;

    constructor(private router: Router) {}

    @HostListener('window:resize', ['$event.target'])
    public onResize(): void {
        this.setBreadCrumbWidth();
    }

    public ngOnChanges(): void {
        this.setBreadCrumbWidth();
    }

    public ngAfterViewInit(): void {
       this.setBreadCrumbWidth();
    }

    public showView(event: Event, view: string, params?: any[]): void {
        let routeArray: any[] = [view];
        if (isNotNullAndNotEmpty(params)) {
            routeArray = [
                view,
                ...params
            ];
        }
        this.router.navigate(routeArray);
    }

    private setBreadCrumbWidth(): void {
        let timer: number = setTimeout(() => {
            let totalWidth: number = this.breadcrumbContainer.nativeElement.offsetWidth;
            let widthSum: number = 0;
            if (this.breadCrumbs && this.breadCrumbs.toArray().length > 0) {
                let linksCount: number = this.breadCrumbs.toArray().length;
                let breadCrumbsArray: ElementRef[] = this.breadCrumbs.toArray();
                for (let element of breadCrumbsArray) {
                    element.nativeElement.style.width = 'auto';
                    let i: number = breadCrumbsArray.indexOf(element);
                    if (i < linksCount - 1) {
                        widthSum += element.nativeElement.offsetWidth;
                    }
                }
                let lastLink: ElementRef = breadCrumbsArray[linksCount - 1];
                lastLink.nativeElement.style.width = ((totalWidth - widthSum) -  (linksCount * 10 )) + 'px';
            }
        });
        clearTimer(timer);
    }
}
