
import { Component, OnInit, HostListener } from '@angular/core';
import { RoutingService, getWindowWidth, getErrorMessage, DataService } from '@app/core';
import { trigger, state, style, AUTO_STYLE, transition, animate } from '@angular/animations';
import { EnvService } from '@app/env.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    animations: [
        trigger('slideUpDown', [
            state('up', style({ height: '0px' })),
            state('down', style({ height: AUTO_STYLE })),
            transition('up => down', animate('400ms ease-in-out')),
            transition('down => up', animate('400ms ease-in-out'))
        ]),
        trigger('subnavGroupToggle', [
            state('up', style({ height: '0px' })),
            state('middle', style({ height: '0px' })),
            state('down', style({ height: AUTO_STYLE })),
            transition('up => down', animate('200ms ease-in-out')),
            transition('down => up', animate('200ms ease-in-out')),
            transition('down => middle', animate('0ms ease-in-out')),
            transition('middle => up', animate('0ms ease-in-out'))
        ])
    ]
})
export class HeaderComponent implements OnInit {

    public index: any = {};
    public showNav: string = 'down';
    public nav: any;
    public appEndpoint: string;
    public navMenuState: any = {
        departmentsMenu: 'up',
        campusMenu: 'up',
        libraryMenu: 'up',
        feeMenu: 'up'
    };
    public active: string;
    private navSubs: any;

    constructor(
        private dataService: DataService,
        private envService: EnvService,
        private routingService: RoutingService) {
            this.appEndpoint = this.envService.APP_ENDPOINT;
            this.fetchIndexSettings();
    }

    @HostListener('window:resize', ['$event.target'])
    public onResize(): void {
        this.setNavs();
    }

    @HostListener('document:click', ['$event'])
    public onClick(event: MouseEvent): void {
        for (let key in this.navMenuState) {
            if (this.navMenuState.hasOwnProperty(key)) {
                this.navMenuState[key] = 'up';
            }
        }
    }
    
    public ngOnInit(): void {
        let width: number = getWindowWidth();
        if (width <= 767) {
            this.showNav = 'up';
        }

        this.navSubs = this.routingService.navEmitter
            .subscribe((info: any) => {
                this.nav = info;
            });
    }

    public ngOnDestroy(): void {
        if (this.navSubs) {
            this.navSubs.unsubscribe();
        }
    }

    public toggleNavs(event: Event): void {
        //event.preventDefault();
        event.stopPropagation();
        this.showNav = this.showNav === 'up' ? 'down' : 'up';
        //this.setOverflow();
    }

    public toggleSubmenu(event: Event, menu: string): void {
        //event.preventDefault();
        event.stopPropagation();
        for (let key in this.navMenuState) {
            if (this.navMenuState.hasOwnProperty(key)) {
                if (key === menu) {
                    this.navMenuState[menu] = this.navMenuState[menu] === 'up' ? 'down' : 'up';
                } else {
                    this.navMenuState[key] = 'up';
                }
            }
        }
    }

    private setNavs(): void {
        for (let key in this.navMenuState) {
            if (this.navMenuState.hasOwnProperty(key)) {
                this.navMenuState[key] = 'up';
            }
        }
        let width: number = getWindowWidth();
        if (width <= 767 && this.showNav === 'down') {
            this.showNav = 'up';
        } else if (width >= 768 && this.showNav === 'up') {
            this.showNav = 'down';
        }
    }

    private fetchIndexSettings(): void {
        this.dataService.get('/web/content/index', true, null)
            .subscribe((info: any) => {
                this.index = info.map.OBJECT ? info.map.OBJECT : {};
            },
            (err: any) => {
                getErrorMessage(err, 'HomeComponent');
            });        
    }
}
