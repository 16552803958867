<div class="row outer-row" [ngClass]="{'single-column': singleCol}">
        <section-title *ngIf="hasTitle" label="OUR COURSES" [hasBG]="true"></section-title>
        <div class="{{singleCol ? 'col-12 departments-col' : 'col-12 col-sm-6 col-md-6 col-lg-6 departments-col'}}">
            <h6>GOVERNMENT AIDED</h6>
            <div class="row dept-type-row">
                <div class="{{singleCol ? 'col-12 dept-type-col' : 'col-12 col-sm-4 col-md-4 col-lg-4 dept-type-col'}}">
                    <a [routerLink]="['/departments', 'civil-engineering']">
                        <div class="img-wrap"><img src="/assets/images/civil.png" alt/></div>
                        <h6 *ngIf="!singleCol">Civil<br />Engineering</h6>
                        <h6 *ngIf="singleCol">Civil Engineering</h6>
                    </a>
                </div>
                <div class="{{singleCol ? 'col-12 dept-type-col' : 'col-12 col-sm-4 col-md-4 col-lg-4 dept-type-col'}}">
                    <a [routerLink]="['/departments', 'mechanical-engineering']">
                        <div class="img-wrap"><img src="/assets/images/mechanical.png" alt/></div>
                        <h6 *ngIf="!singleCol">Mechanical<br />Engineering</h6>
                        <h6 *ngIf="singleCol">Mechanical Engineering</h6>
                    </a>
                </div>
                <div class="{{singleCol ? 'col-12 dept-type-col' : 'col-12 col-sm-4 col-md-4 col-lg-4 dept-type-col'}}">
                    <a [routerLink]="['/departments', 'electrical-and-electronics-engineering']">
                        <div class="img-wrap"><img src="/assets/images/electrical_and_electronics.png" alt/></div>
                        <h6 *ngIf="!singleCol">Electrical & Electronics<br />Engineering</h6>
                        <h6 *ngIf="singleCol">Electrical & Electronics Engineering</h6>
                    </a>
                </div>
            </div>
        </div>
        <div class="{{singleCol ? 'col-12 departments-col' : 'col-12 col-sm-6 col-md-6 col-lg-6 departments-col'}}">
            <h6>SELF FINANCING</h6>
            <div class="row dept-type-row">
                <div class="{{singleCol ? 'col-12 dept-type-col' : 'col-12 col-sm-4 col-md-4 col-lg-4 dept-type-col'}}">
                    <a [routerLink]="['/departments', 'automobile-engineering']">
                        <div class="img-wrap"><img src="/assets/images/automobile.png" alt/></div>
                        <h6 *ngIf="!singleCol">Automobile<br />Engineering</h6>
                        <h6 *ngIf="singleCol">Automobile Engineering</h6>
                    </a>
                </div>
                <div class="{{singleCol ? 'col-12 dept-type-col' : 'col-12 col-sm-4 col-md-4 col-lg-4 dept-type-col'}}">
                    <a [routerLink]="['/departments', 'computer-engineering']">
                        <div class="img-wrap"><img src="/assets/images/computer.png" alt/></div>
                        <h6 *ngIf="!singleCol">Computer<br />Engineering</h6>
                        <h6 *ngIf="singleCol">Computer Engineering</h6>
                    </a>
                </div>
                <div class="{{singleCol ? 'col-12 dept-type-col' : 'col-12 col-sm-4 col-md-4 col-lg-4 dept-type-col'}}">
                    <a [routerLink]="['/departments', 'electronics-engineering']">
                        <div class="img-wrap"><img src="/assets/images/electronics.png" alt/></div>
                        <h6 *ngIf="!singleCol">Electronics<br />Engineering</h6>
                        <h6 *ngIf="singleCol">Electronics Engineering</h6>
                    </a>
                </div>
            </div>
        </div>
    </div>