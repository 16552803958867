import { Component, Input } from '@angular/core';
import { Message } from '@app/core';

@Component({
    selector: 'activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent {

    @Input() public error: Message;
    @Input() public activities: any[];
}