import { Routes } from '@angular/router';

import { CampusComponent } from '@app/campus-module/campus/campus.component';
export const CampusRoutes: Routes = [
  {
    path: '',
    component: CampusComponent,
    data: {
      title: ''
    }
  }
];
