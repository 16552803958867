import { Routes } from '@angular/router';

import { AlumniComponent } from '@app/alumni/alumni.component';
export const AlumniRoutes: Routes = [
  {
    path: '',
    component: AlumniComponent,
    data: {
      title: ''
    }
  }
];
