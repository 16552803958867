
/*! ****************************************************************************
Author: Libu T.Babu, email: crazylibu@yahoo.com
***************************************************************************** */
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'sub-body',
    templateUrl: './sub-body.component.html',
    styleUrls: ['./sub-body.component.css']
})
export class SubBodyComponent {

    @Input() public subTitle: string;
}
