import { Component, Input } from '@angular/core';

@Component({
    selector: 'sub-banner',
    templateUrl: './sub-banner.component.html',
    styleUrls: ['./sub-banner.component.css']
})

export class SubBannerComponent {

    @Input() public index: any = {};
}