import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { BreadcrumbComponent, ModalWindowComponent, HeaderComponent, FooterComponent, SectionTitleComponent, SocialLinkComponent, MaterialInputComponent, SpinnerComponent, LoaderComponent, MessageBoxComponent, ChosenComponent, ChosenFilterPipe, ToggleComponent, ImageUploadComponent, FileUploadComponent, MainBodyComponent, SubBodyComponent, ImageWithTitlesComponent, PreloadPictureComponent, BoxPanelComponent, ImageThumbnailComponent, TogglePanelComponent, Chosen2FilterPipe, Chosen2Component, MonthPickerComponent, LeftTabComponent, LeftTabsComponent, RadioComponent, DatePickerComponent, DownloadButtonComponent, ApplicationDownloadComponent } from '@app/shared/components';
import { DraggableDirective } from '@app/shared/directives';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { DateFormatPipe, URLPipe, LineBreakPipe, MomentToStringPipe, TimezonePipe, TabCountPipe, NumberDecimalPipe, AdmissionYearPipe, FileNamePipe, FullNamePipe, ApplicationTypePipe, CommunityCatholicPipe, AddressPipe, AdmissionsPipe } from './pipe';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { DepartmentsComponent } from './components/departments/departments.component';
import { TopTabComponent, TopTabsComponent } from './components/top-tabs';
import { AllotmentStatusPipe } from './pipe/allotment-status.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    NgSlimScrollModule,
    ClipboardModule
  ],
  declarations: [
    BreadcrumbComponent,
    ModalWindowComponent,
    DraggableDirective,
    HeaderComponent,
    FooterComponent,
    SectionTitleComponent,
    SocialLinkComponent,
    MaterialInputComponent,
    SpinnerComponent,
    LoaderComponent,
    MessageBoxComponent,
    DateFormatPipe,
    URLPipe,
    LineBreakPipe,
    ChosenComponent,
    MomentToStringPipe,
    ChosenFilterPipe,
    TimezonePipe,
    ToggleComponent,
    TabCountPipe,
    NumberDecimalPipe,
    ImageUploadComponent,
    FileUploadComponent,
    MainBodyComponent,
    SubBodyComponent,
    ImageWithTitlesComponent,
    PreloadPictureComponent,
    BoxPanelComponent,
    AdmissionYearPipe,
    DepartmentsComponent,
    ImageThumbnailComponent,
    TogglePanelComponent,
    TopTabComponent,
    TopTabsComponent,
    FileNamePipe,
    FullNamePipe,
    Chosen2FilterPipe,
    Chosen2Component,
    MonthPickerComponent,
    LeftTabComponent,
    LeftTabsComponent,
    RadioComponent,
    DatePickerComponent,
    DownloadButtonComponent,
    ApplicationDownloadComponent,
    ApplicationTypePipe,
    CommunityCatholicPipe,
    AddressPipe,
    AllotmentStatusPipe,
    AdmissionsPipe
  ],
  exports: [
    BreadcrumbComponent,
    ModalWindowComponent,
    DraggableDirective,
    HeaderComponent,
    FooterComponent,
    AngularFontAwesomeModule,
    SectionTitleComponent,
    SocialLinkComponent,
    MaterialInputComponent,
    SpinnerComponent,
    LoaderComponent,
    MessageBoxComponent,
    DateFormatPipe,
    URLPipe,
    LineBreakPipe,
    ChosenComponent,
    MomentToStringPipe,
    ChosenFilterPipe,
    TimezonePipe,
    ToggleComponent,
    TabCountPipe,
    NumberDecimalPipe,
    ImageUploadComponent,
    FileUploadComponent,
    MainBodyComponent,
    SubBodyComponent,
    ImageWithTitlesComponent,
    PreloadPictureComponent,
    BoxPanelComponent,
    AdmissionYearPipe,
    DepartmentsComponent,
    ImageThumbnailComponent,
    TogglePanelComponent,
    TopTabComponent,
    TopTabsComponent,
    FileNamePipe,
    FullNamePipe,
    Chosen2FilterPipe,
    Chosen2Component,
    MonthPickerComponent,
    LeftTabComponent,
    LeftTabsComponent,
    RadioComponent,
    DatePickerComponent,
    DownloadButtonComponent,
    ApplicationDownloadComponent,
    ApplicationTypePipe,
    CommunityCatholicPipe,
    AddressPipe,
    AllotmentStatusPipe,
    AdmissionsPipe
  ],
  entryComponents: []
})
export class SharedModule {}
