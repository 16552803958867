
/*! ****************************************************************************
Fresh Chat Widget, author: Libu T.Babu, email: libu@trueinfluence.in
***************************************************************************** */
import { Component, Input } from '@angular/core';

@Component({
    selector: 'section-title',
    templateUrl: './section-title.component.html',
    styleUrls: ['./section-title.component.css']
})
export class SectionTitleComponent {

    @Input() public label: string;
    @Input() public hasBG: boolean;
 }
