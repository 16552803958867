export class Nav {
    public label: string;
    public icon: string;
    public state: string;
    public target?: string;
    public collapsed?: 'true'|'false';
    public role?: 'Admin' | 'Staff' | 'Guest';
    public subNavs?: Nav[];
    public param?: any;
}
