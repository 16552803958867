import { Routes } from '@angular/router';

export const AppRoutes: Routes = [
  {
    path: 'index',
    loadChildren: () =>
          import('./home-module/home.module').then(m => m.HomeModule)
  },
  {
    path: 'about-us',
    loadChildren: () =>
          import('./about-us-module/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: 'contact-us',
    loadChildren: () =>
          import('./contact-us-module/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'admissions',
    loadChildren: () =>
          import('./admissions-module/admissions.module').then(m => m.AdmissionsModule)
  },
  {
    path: 'campus/:campus',
    loadChildren: () =>
          import('./campus-module/campus.module').then(m => m.CampusModule)
  },
  {
    path: 'departments/:department',
    loadChildren: () =>
          import('./department-module/department.module').then(m => m.DepartmentModule)
  },
  {
    path: 'grievance-redressal-cell',
    loadChildren: () =>
          import('./quick-links-module/quick-links.module').then(m => m.QuickLinksModule)
  },
  {
    path: 'anti-ragging-cell',
    loadChildren: () =>
          import('./quick-links-module/quick-links.module').then(m => m.QuickLinksModule)
  },
  {
    path: 'internal-quality-assurance-cell',
    loadChildren: () =>
          import('./quick-links-module/quick-links.module').then(m => m.QuickLinksModule)
  },
  {
    path: 'internal-complaints-committee',
    loadChildren: () =>
          import('./quick-links-module/quick-links.module').then(m => m.QuickLinksModule)
  },
  {
    path: 'sc-st-monitoring-committee',
    loadChildren: () =>
          import('./quick-links-module/quick-links.module').then(m => m.QuickLinksModule)
  },
  {
    path: 'extension-of-approvals',
    loadChildren: () =>
          import('./quick-links-module/quick-links.module').then(m => m.QuickLinksModule)
  },
  {
    path: 'alumni',
    loadChildren: () =>
          import('./alumni/alumni.module').then(m => m.AlumniModule)
  },
  {
    path: 'home',
    pathMatch: 'full',
    redirectTo: 'index'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'index'
  }
];
