import { Component, OnInit } from '@angular/core';
import { RoutingService, DataService, getErrorMessage } from '@app/core';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    public index: any = {};

    constructor(
        private routingService: RoutingService,
        private dataService: DataService) { }

    public ngOnInit(): void {
        this.routingService.emitRoute('/index');
        this.fetchIndexSettings();
    }

    private fetchIndexSettings(): void {
        this.dataService.get('/web/content/index', true, null)
            .subscribe((info: any) => {
                let index: any = info.map.OBJECT ? info.map.OBJECT : {};
                index.titleRight = index.titleRight && index.titleRight.trim().length > 0 ? index.titleRight : null;
                this.index = index;

            },
            (err: any) => {
                getErrorMessage(err, 'HomeComponent');
            });        
    }
}