import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import {
  ApiPrefixInterceptor,
  ErrorHandlerInterceptor,
  HttpService
} from '@app/core/http';
import { LocalStorageService, RoutingService } from '@app/core/service';
import {
  UtilService,
  DataService
} from '@app/core/service';
import { RouteReusableStrategy } from '@app/core/route-reusable-strategy';
import { CoolStorageModule } from '@angular-cool/storage';
import { SuperAdminGuard } from './guard';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, CoolStorageModule],
  declarations: [],
  providers: [
    LocalStorageService,
    ErrorHandlerInterceptor,
    ApiPrefixInterceptor,
    UtilService,
    DataService,
    RoutingService,
    SuperAdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    }
  ]
})
export class CoreModule {}
