
/*! ****************************************************************************
Fresh Chat Widget, author: Libu T.Babu, email: libu@trueinfluence.in
***************************************************************************** */
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent {}
