/*! *********************************************
 Author: Libu T.Babu, email: crazylibu@yahoo.com
*********************************************** */
import { Component, Input, SimpleChanges } from '@angular/core';
import { clearTimers } from '@app/core';

@Component({
    selector: 'preload-picture',
    templateUrl: './preload-picture.component.html',
    styleUrls: ['./preload-picture.component.css']
})
export class PreloadPictureComponent {

    @Input() public folder: string;
    @Input() public fileName: string;
    @Input() public type: string;
    public picLoaded: boolean = false;
    private timers: number[] = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if ('fileName' in changes) {
            this.picLoaded = false;
        }
    }
    public setPicture(event: Event): void {
        let timer: number = setTimeout(() => {
            this.picLoaded = true;
        });
        this.timers.push(timer);
    }

    public ngOnDestroy(): void {
        clearTimers(this.timers);
    }
}
