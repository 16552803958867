<div>
  <app-header></app-header>
</div>
<social-link position="in"></social-link>
<div *ngIf="route !== '/index'">
  <breadcrumb [links]="links"></breadcrumb>
</div>
<div>
<router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
<div>
  <app-footer></app-footer>
</div>
