/*! *********************************************
 Author: Libu T.Babu, email: crazylibu@yahoo.com
*********************************************** */
import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { trigger, state, style, AUTO_STYLE, transition, animate } from '@angular/animations';
import { clearTimers } from '@app/core';

@Component({
    selector: 'toggle-panel',
    templateUrl: './toggle-panel.component.html',
    styleUrls: ['./toggle-panel.component.css'],
    animations: [
        trigger('slideUpDown', [
            state('close', style({
                height: '0px',
                display: 'none'
            })),
            state('open', style({
                display: 'block',
                height: AUTO_STYLE
            })),
            transition('close => open', animate('400ms ease-in-out')),
            transition('open => close', animate('400ms ease-in-out'))
        ]),
    ]
})
export class TogglePanelComponent implements OnChanges, OnDestroy {

    @Input() public panelTitle: string;
    @Input() public icon: string;
    @Input() public hideToggleButton: boolean = false;
    @Input() public panelState: string = 'close';
    @ViewChild('body', {static: false}) public body: ElementRef;
    @ViewChild('content', {static: false}) public content: ElementRef;
    private timers: number[] = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if ('panelState' in changes) {
            this.setOverflow();
        }
    }

    public ngOnInit(): void {
            this.setOverflow();
    }

    public togglePanel(event: Event): void {
        if (!this.hideToggleButton) {
            this.panelState = this.panelState === 'close' ? 'open' : 'close';
            this.setOverflow();
        }
    }

    public ngOnDestroy(): void {
        clearTimers(this.timers);
    }

    private setOverflow(): void {
        if (this.body) {
            if (this.panelState === 'open') {
                let timer: any = setTimeout(() => {
                    this.body.nativeElement.style.overflow = 'visible';
                    this.content.nativeElement.style.overflow = 'visible';
                    this.content.nativeElement.style.marginTop = '0px';
                    this.timers.push(timer);
                }, 400);
            } else {
                this.body.nativeElement.style.overflow = 'hidden';
                this.content.nativeElement.style.overflow = 'hidden';
                this.content.nativeElement.style.marginTop = '-1px';
            }
        }
    }
}
