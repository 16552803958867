import { Component } from '@angular/core';
import { DataService } from '@app/core';

@Component({
    selector: 'alumni',
    templateUrl: './alumni.component.html',
    styleUrls: ['./alumni.component.css']
})
export class AlumniComponent {
    
    constructor(private dataService: DataService) { }
}