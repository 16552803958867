<message-box *ngIf="error" [message]="error" type="Error"></message-box>
<div *ngIf="!error" class="row faculty" [ngClass]="{'no-pic': !hod && !spdt}">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th>Name</th>
                <th>Designation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let faculty of staffs">
                <td>{{faculty?.staff?.name}}</td>
                <td>{{faculty.designation}}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="hod" class="pic-col">
        <div class="thumbnail-wrapper">
            <image-with-titles mainTitle="Head of the Department" [subTitle]="hod?.staff?.name" [transform]="true" height="175px" width="145px" folder="/assets/images" [fileName]="(hod?.staff?.name) | myFileNamePipe" type="pre_avatar_staff"></image-with-titles>
        </div>
    </div>
    <div *ngIf="spdt" class="pic-col">
        <div class="thumbnail-wrapper">
            <image-with-titles [mainTitle]="spdt?.department?.includes('Office Administration') ? 'Office Superintendent' : spdt?.designation" [subTitle]="spdt?.staff?.name" [transform]="true" height="175px" width="145px" folder="/assets/images" [fileName]="(spdt?.staff?.name) | myFileNamePipe" type="pre_avatar_staff"></image-with-titles>
        </div>
    </div>
</div>