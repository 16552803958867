import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isMandatoryPhoneNumber, isMandatoryEmail, getError, Message, DataService } from '@app/core';

@Component({
    selector: 'contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent {
    
    public form: FormGroup;
    public error: Message;
    public success: Message;
    public isSubmitClicked: boolean = false;
    public submitting: boolean = false;
    
    constructor(
        private formBuilder: FormBuilder,
        private dataService: DataService) {

        this.form = this.formBuilder.group({
            name: [null, Validators.required],
            email: [null, isMandatoryEmail],
            phone: [null, isMandatoryPhoneNumber],
            subject: [null, Validators.required],
            message: [null, Validators.required]
        });
    }

    public ngOnInit(): void {
        this.form.valueChanges
            .subscribe((form: any) => {
                this.error = null;
                this.success = null;
            });
    }

    public onSubmit(event: Event): void {
        this.error = null;
        this.success = null;
        this.isSubmitClicked = true;
        if (this.form.valid) {
            this.submitting = true;
            let payload: any = this.form.getRawValue();
            payload.message = `
                Sender: ${payload.name}<br/>
                Email: ${payload.email}<br/>
                Phone: ${payload.phone}<br/><br/>
                Message: ${payload.message}
            `;
            this.dataService.post('/candidate/noauth/submitmessage', true, payload)
                .subscribe((info: any) => {
                    if (info) {
                        this.form.reset();
                        this.isSubmitClicked = false;
                        this.success = {
                            message: 'Succesfully submitted your request. We will get back to you soon.',
                            shortMessage: 'Succesfully submitted.'
                        };
                    } else {
                        this.error = getError('Null returned.', 'ContactUsComponent');
                    }
                    this.submitting = false;
                },
                (err: any) => {
                    this.submitting = false;
                    this.error = getError(err, 'ContactUsComponent');
                });
        } else {
            this.error = {
                message: 'Oops! The details you have entered is invalid.',
                shortMessage: 'Invalid details.'
            };
            console.error('onSubmit() failed @ ContactUsComponent. Invalid data.');
        }
    }
}