<main-body mainTitle="About Us">
    <div>
        <div class="patron-wrapper">
            <image-with-titles [transform]="true" height="315px" width="218px" folder="/assets/images" fileName="mother_of_carmel" type="pre_avatar_patron" mainTitle="Our Patron" subTitle="Mother of carmel"></image-with-titles>
            <image-with-titles [transform]="true" height="315px" width="218px" folder="/assets/images" fileName="st_kuriakose_elias_chavara" type="pre_avatar_patron" mainTitle="Our Inspiration" subTitle="St. Kuriakose Elias Chavara"></image-with-titles>
            <image-with-titles [transform]="true" height="315px" width="218px" folder="/assets/images" [fileName]="'Rev. Fr. Gilbert Palakkunnel, CMI' | myFileNamePipe" type="pre_avatar_patron" mainTitle="Our Founder" subTitle="Rev. Fr. Gilbert Palakkunnel, CMI"></image-with-titles>
        </div>
        Carmel Polytechnic College was established in 1958 by <span class="bold">Very Rev. Fr. Gilbert Palakunnel CMI</span>, and is 
        managed by <span class="bold">CMI Fathers</span> - a priestly order reputed for their contributions in the field of education 
        and healthcare. The institution draws spiritual inspiration from <span class="bold">St. Kuriakose Elias Chavara</span>. 
        Ever since its inception, the college has been striving for realizing the <span class="bold">CMI Vision of Education</span>, 
        of achieving holistic excellence through the synergy of all factors.<br/><br/>
        The present chairman of the college is Fr. Thomas Choolaparampil CMI, and the present Principal is 
        Fr. James Devasia CMI. The college is recognized by the AICTE, aided by the state and central 
        governments and the college offers 3 year diploma courses in Civil, Mechanical and Electrical 
        & Electronics Engineering in the Aided Scheme and Electronics, Computer and Automobile Engineering 
        in the Self-financing Scheme with a total intake of 378 students per year.<br/><br/>
        Situated 8 KM south of Alappuzha town by the side of NH 66, Carmel occupies a unique position in 
        the field of technical education in the country. The management, by its bottom up approach encourages 
        everybody in the campus to put his best foot forward always.
    </div>
    <sub-body subTitle="Vision & Mission">
        <div class="row">
            <div class="col-12 col-sm-6 vision-wrap">
                <box-panel class="vision" boxMainTitle="Our Vision" boxSubTitle="SAPIENTIA ET LABOUR (Wisdom & Labour)">
                    To be a Centre of Excellence in Technical education and training equipped with elevated learning 
                    environment catering the timely demands of the industry and society.
                </box-panel>
            </div>
            <div class="col-12 col-sm-6 vision-wrap">
                <box-panel class="vision" boxMainTitle="Our Mission">
                    To mould technically qualified technicians by providing elegant faculty 
                    and well-equipped infrastructure.<br/><br/>
                    To transform students into integrated professionals who uphold the needs of the society.<br/><br/>
                    To create entrepreneurs befitting environmental and global needs of the time.
                </box-panel>
            </div>
        </div>
    </sub-body>
    <sub-body subTitle="Affiliation">
        Carmel polytechnic is registered under the Department of Technical Education, Kerala State which imparts 
        training for technical courses leading to the award of the Diploma of the State Board of the Technical 
        Education.
    </sub-body>
    <sub-body subTitle="Management">
        <div class="row succession">
            <div class="pic-col">
                <div class="thumbnail-wrapper">
                    <image-with-titles mainTitle="Chairman" subTitle="Fr. Thomas Choolaparampil, CMI" [transform]="true" height="158px" width="126px" folder="/assets/images" [fileName]="'Fr. Thomas Choolaparampil, CMI' | myFileNamePipe" type="pre_avatar_staff"></image-with-titles>
                </div>
                <div class="thumbnail-wrapper">
                    <image-with-titles mainTitle="Principal" subTitle="Fr. James Devasia CMI, M.Tech." [transform]="true" height="158px" width="126px" folder="/assets/images" [fileName]="'Fr. James Devasia CMI, M.Tech' | myFileNamePipe" type="pre_avatar_staff"></image-with-titles>
                </div>
                <div class="thumbnail-wrapper">
                    <image-with-titles mainTitle="Vice Principal" subTitle="Fr. Jacob Kurian CMI, M.Tech." [transform]="true" height="158px" width="126px" folder="/assets/images" [fileName]="'Fr. Jacob Kurian CMI, M.Tech' | myFileNamePipe" type="pre_avatar_staff"></image-with-titles>
                </div>
                <div class="thumbnail-wrapper">
                    <image-with-titles mainTitle="Bursar" subTitle="Fr. Bijo M.D. CMI, M.Tech." [transform]="true" height="158px" width="126px" folder="/assets/images" [fileName]="'Fr. Bijo M.D. CMI, M.Tech' | myFileNamePipe" type="pre_avatar_staff"></image-with-titles>
                </div>
            </div>
            <toggle-panel class="first" [panelTitle]="'Succession List'" [panelState]="'open'" [hideToggleButton]="true" icon="<i class='fa fa-level-down'></i>">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Served Years</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1958 - 1987</td>
                            <td>Fr. Gilbert, CMI.</td>
                        </tr>
                        <tr>
                            <td>1987 - 1996</td>
                            <td>Fr. Joseph Palakunnel, CMI</td>
                        </tr>
                        <tr>
                            <td>1996 - 1999</td>
                            <td>Fr. Sebastian Palathra, CMI</td>
                        </tr>
                        <tr>
                            <td>1999 - 2002</td>
                            <td>Fr. Mathew Berchmans Kattapuram, CMI</td>
                        </tr>
                        <tr>
                            <td>2002 - 2005</td>
                            <td>Fr. Probus Perumalil, CMI</td>
                        </tr>
                        <tr>
                            <td>2005 - 2008</td>
                            <td>Fr. Joseph Chirayil, CMI</td>
                        </tr>
                        <tr>
                            <td>2008 - 2011</td>
                            <td>Fr. Joseph Palakunnel, CMI</td>
                        </tr>
                        <tr>
                            <td>2011 - 2016</td>
                            <td>Fr. Gregory Perumalil, CMI</td>
                        </tr>
                        <tr>
                            <td>2016 - 2023</td>
                            <td>Fr. Mathew Arekalam, CMI</td>
                        </tr>
                        <tr>
                            <td>2023 - Present</td>
                            <td>Fr. Thomas Choolaparampil, CMI</td>
                        </tr>
                    </tbody>
                </table>
            </toggle-panel>
            <toggle-panel [panelTitle]="'Succession List'" [panelState]="'open'" [hideToggleButton]="true" icon="<i class='fa fa-level-down'></i>">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Served Years</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1958 - 1961</td>
                            <td>Mr. K. Balaram, B.E.</td>
                        </tr>
                        <tr>
                            <td>1961 - 1962</td>
                            <td>Mr. C.I. Varghese, B.E.</td>
                        </tr>
                        <tr>
                            <td>1962 - 1968</td>
                            <td>Mr. Zacharia George B.Sc., M.S.</td>
                        </tr>
                        <tr>
                            <td>1968 - 1996</td>
                            <td>Mr. P. Balan B.Sc. (Engg)</td>
                        </tr>
                        <tr>
                            <td>1996 - 1997</td>
                            <td>Mr. P.U. Varghese B.Sc. (Engg)</td>
                        </tr>
                        <tr>
                            <td>1997 - 1999</td>
                            <td>Mr. K.C. Mathew B.Sc. (Engg)</td>
                        </tr>
                        <tr>
                            <td>1999 - 2000</td>
                            <td>Mr. K.B. Venkitachalam Iyer B.Sc., B.Sc. (Engg)</td>
                        </tr>
                        <tr>
                            <td>2000 - 2002</td>
                            <td>Mr. C.A. Thomas B.Sc. (Engg), M.I.E.</td>
                        </tr>
                        <tr>
                            <td>2002 - 2015</td>
                            <td>Fr. Cyriac Kurian CMI, B.E.</td>
                        </tr>
                        <tr>
                            <td>2015 - 2016</td>
                            <td>Fr. Kunjumon Job CMI, M.Tech. (In Charge)</td>
                        </tr>
                        <tr>
                            <td>2016 - 2019</td>
                            <td>Mr. Thomaskutty Jose, M.Tech.</td>
                        </tr>
                        <tr>
                            <td>2019 - 2020</td>
                            <td>Mr. D. Biju Das, M.Tech.</td>
                        </tr>
                        <tr>
                            <td>2020 - 2021</td>
                            <td>Fr. Kunjumon Job CMI, M.Tech.</td>
                        </tr>
                        <tr>
                            <td>2021 - 2022</td>
                            <td>Fr. Josekutty Chacko CMI, M.Tech.</td>
                        </tr>
                        <tr>
                            <td>2022 - Present</td>
                            <td>Fr. James Devasia CMI, M.Tech.</td>
                        </tr>
                    </tbody>
                </table>
            </toggle-panel>
        </div>
    </sub-body>
</main-body>