import { EventEmitter, Injectable } from '@angular/core';
import { clone, clearTimer } from '../util';

@Injectable()
export class RoutingService {

    public navEmitter: EventEmitter<any> = new EventEmitter<any>();
    public breadcrumbEmitter: EventEmitter<any[]> = new EventEmitter<any[]>();
    public indexRouteEmitter: EventEmitter<string> = new EventEmitter<string>();
    public userEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    private breadcrumbConfig: any = {
        index: {
            html: 'Home',
            view: '/index',
            params: null,
            module: 'web',
            authRequired: false
        },
        aboutUs: {
            html: 'About Us',
            view: '/about-us',
            params: null,
            module: 'web',
            authRequired: false
        },
        contactUs: {
            html: 'Contact Us',
            view: '/contact-us',
            params: null,
            module: 'web',
            authRequired: false
        },
        admissions: {
            html: 'Admissions',
            view: '/admissions',
            params: null,
            module: 'web',
            authRequired: false
        },
        departments: {
            html: '',
            view: '/departments',
            params: null,
            module: 'web',
            authRequired: false
        },
        campus: {
            html: '',
            view: '/campus',
            params: null,
            module: 'web',
            authRequired: false
        },
        grievanceRedressal: {
            html: 'Grievance Redressal Cell',
            view: '/grievance-redressal-cell',
            params: null,
            module: 'web',
            authRequired: false
        },
        antiRagging: {
            html: 'Anti-Ragging Cell',
            view: '/anti-ragging-cell',
            params: null,
            module: 'web',
            authRequired: false
        },
        extensionOfApprovals: {
            html: 'Extension of Approvals',
            view: '/extension-of-approvals',
            params: null,
            module: 'web',
            authRequired: false
        },
        alumni: {
            html: 'Alumni',
            view: '/alumni',
            params: null,
            module: 'web',
            authRequired: false
        }
    };

    public setBreadcrumbAndNav(url: string, params?: any) {
        let breadcrumb: any[] = [];
        let nav: any = {activeNav: 'index'};
        if (url === '/index') {
            breadcrumb.push(this.breadcrumbConfig.index);
            nav = {main: 'Home', sub: null};
        } else if (url === '/about-us') {
            breadcrumb.push(this.breadcrumbConfig.index);
            breadcrumb.push(this.breadcrumbConfig.aboutUs);
            nav = {main: 'About Us', sub: null};
        } else if (url === '/contact-us') {
            breadcrumb.push(this.breadcrumbConfig.index);
            breadcrumb.push(this.breadcrumbConfig.contactUs);
            nav = {main: 'Contact Us', sub: null};
        } else if (url === '/admissions') {
            breadcrumb.push(this.breadcrumbConfig.index);
            breadcrumb.push(this.breadcrumbConfig.admissions);
            nav = {main: 'Admissions', sub: null};
        } else if (url === '/courses') {
            breadcrumb.push(this.breadcrumbConfig.index);
            breadcrumb.push(this.breadcrumbConfig.courses);
            nav = {main: 'Courses', sub: null};
        } else if (url === '/grievance-redressal-cell') {
            breadcrumb.push(this.breadcrumbConfig.index);
            breadcrumb.push(this.breadcrumbConfig.grievanceRedressal);
            nav = {main: null, sub: null};
        } else if (url === '/anti-ragging-cell') {
            breadcrumb.push(this.breadcrumbConfig.index);
            breadcrumb.push(this.breadcrumbConfig.antiRagging);
            nav = {main: null, sub: null};
        } else if (url === '/extension-of-approvals') {
            breadcrumb.push(this.breadcrumbConfig.index);
            breadcrumb.push(this.breadcrumbConfig.extensionOfApprovals);
            nav = {main: null, sub: null};
        } else if (url.startsWith('/departments') && params) {
            let subMenu: string = 'Civil Engineering';
            if (params && params['department'] === 'mechanical-engineering') {
                subMenu = 'Mechanical Engineering';
            } else if (params && params['department'] === 'electrical-and-electronics-engineering') {
                subMenu = 'Electrical and Electronics Engineering';
            } else if (params && params['department'] === 'automobile-engineering') {
                subMenu = 'Automobile Engineering';
            } else if (params && params['department'] === 'computer-engineering') {
                subMenu = 'Computer Engineering';
            } else if (params && params['department'] === 'electronics-engineering') {
                subMenu = 'Electronics Engineering';
            } else if (params && params['department'] === 'general-engineering') {
                subMenu = 'General Engineering';
            } else if (params && params['department'] === 'workshop') {
                subMenu = 'Workshop';
            } else if (params && params['department'] === 'office-administration') {
                subMenu = 'Office Administration';
            }
            let link: any = clone(this.breadcrumbConfig.departments);
            link.html = subMenu;
            link.params = [params['department']];
            breadcrumb.push(this.breadcrumbConfig.index);
            breadcrumb.push(link);
            nav = {main: 'Departments', sub: subMenu};
        } else if (url.startsWith('/campus') && params) {
            let subMenu: string = 'Facilities';
            if (params['campus'] === 'activities') {
                subMenu = 'Activities';
            }
            let link: any = clone(this.breadcrumbConfig.campus);
            link.html = subMenu;
            link.params = [params['campus']];
            breadcrumb.push(this.breadcrumbConfig.index);
            breadcrumb.push(link);
            nav = {main: 'Campus', sub: subMenu};
        } else if (url === '/alumni') {
            breadcrumb.push(this.breadcrumbConfig.index);
            breadcrumb.push(this.breadcrumbConfig.alumni);
            nav = {main: 'Alumni', sub: null};
        }
        this.navEmitter.emit(nav);
        this.breadcrumbEmitter.emit(breadcrumb);
        this.emitUser();
    }

    public emitRoute(route: string): void {
        this.indexRouteEmitter.emit(route);
    }

    public emitUser(): void {
        this.userEmitter.emit(false);
        let timer: number = setTimeout(() => {
            this.userEmitter.emit(true);
            clearTimer(timer);
        });
    }
}
