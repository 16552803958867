import { Component, ElementRef, ViewChild, HostListener, Input } from '@angular/core';
import { clearTimers, getWindowHeight, DataService } from '@app/core';
import { AppConfig } from '@app/app.config';

@Component({
    selector: 'banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.css']
})
export class BannerComponent {

    @Input() public index: any = {};
    @ViewChild('banner', {static: false}) public banner: ElementRef;
    @ViewChild('mainSlide', {static: false}) public mainSlide: ElementRef;
    public slide: string;
    public slideNext: string;
    public slideIndex: number;
    public bannerSlides: string[] = AppConfig.BANNER_SLIDES;
    private timers: number[] = [];

    constructor(private dataService: DataService) {
        this.slideIndex = this.dataService.updateBannerIndex();
        this.slide = this.bannerSlides[this.slideIndex];
    }

    @HostListener('window:resize', ['$event.target'])
    public onResize(): void {
        this.setElementHeight();
    }

    public ngOnInit(): void {
        let timer: number = setTimeout(() => {
            this.setElementHeight();
        });
        this.timers.push(timer);
    }

    public ngOnDestroy(): void {
        clearTimers(this.timers);
    }

    public ngAfterViewInit(): void {
        let timer: number = setTimeout(() => {
            this.setElementHeight();
        });
        this.timers.push(timer);
    }

    public showThis(index: number): void {
        if (!this.slideNext) {
            this.slideIndex = index;
            this.slideNext = this.bannerSlides[index];
            this.fade();
            let timer: any = setTimeout(() => {
                this.slide = this.bannerSlides[index];
                this.show();
            }, 300);
            this.timers.push(timer);
        }
    }

    private setElementHeight(): void {
        if (this.banner && this.banner.nativeElement) {
            this.banner.nativeElement.style.height = (getWindowHeight() - 102) + 'px';
        }
    }

    private fade(): void {
        if (this.mainSlide && this.mainSlide.nativeElement) {
            this.mainSlide.nativeElement.style['-webkit-transition'] = 'all 0.3s ease';
            this.mainSlide.nativeElement.style['-moz-transition'] = 'all 0.3s ease';
            this.mainSlide.nativeElement.style['-o-transition'] = 'all 0.3s ease';
            this.mainSlide.nativeElement.style.transition = 'all 0.3s ease';
            this.mainSlide.nativeElement.style.opacity = 0;
        }
        let timer: any = setTimeout(() => {
            this.mainSlide.nativeElement.style['-webkit-transition'] = 'none';
            this.mainSlide.nativeElement.style['-moz-transition'] = 'none';
            this.mainSlide.nativeElement.style['-o-transition'] = 'none';
            this.mainSlide.nativeElement.style.transition = 'none';
        }, 300);
        this.timers.push(timer);
    }

    private show(): void {
        if (this.mainSlide && this.mainSlide.nativeElement) {
            this.mainSlide.nativeElement.style.opacity = 1;
            this.slideNext = null;
        }
    }
}