import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()
export class SuperAdminGuard implements CanActivate {

  public canActivate(): boolean {
    return true;
  }
}
