import { Component, Input } from '@angular/core';
import { Message } from '@app/core';

@Component({
    selector: 'labs',
    templateUrl: './labs.component.html',
    styleUrls: ['./labs.component.css']
})
export class LabsComponent {

    @Input() public error: Message;
    @Input() public workshops: any[] = [];
}