<div class="row outer-row">
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-2 shortcut-col">
        <a [routerLink]="['/contact-us']">
            <div class="sh-circle sky"><fa name="envelope"></fa></div>
            <h6>CONTACT US</h6>
        </a>
    </div>
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-2 shortcut-col">
        <a [routerLink]="['/admissions']">
            <div class="sh-circle pista"><fa name="handshake-o"></fa></div>
            <h6>ADMISSIONS</h6>
        </a>
    </div>
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-2 shortcut-col">
        <a [routerLink]="['/departments', 'civil-engineering']">
            <div class="sh-circle brick"><fa name="graduation-cap"></fa></div>
            <h6>COURSES</h6>
        </a>
    </div>
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-2 shortcut-col">
        <a [routerLink]="['/campus', 'facilities']">
            <div class="sh-circle pink"><fa name="institution"></fa></div>
            <h6>CAMPUS</h6>
        </a>
    </div>
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-2 shortcut-col">
        <a href="#">
            <div class="sh-circle yellow"><fa name="calendar-check-o"></fa></div>
            <h6>EVENTS</h6>
        </a>
    </div>
    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-2 shortcut-col">
        <a href="">
            <div class="sh-circle grey"><fa name="image"></fa></div>
            <h6>GALLERY</h6>
        </a>
    </div>
</div>