<div class="row outer-row">
    <div class="col-xs-6 col-sm-4 sub-banner-col">
        <a [routerLink]="['/about-us']">
            <div class="img-wrap"><img src="/assets/images/our_inspiration.png" alt/></div>
            <h6>OUR INSPIRATION</h6>
            <div class="description">
                Carmel Polytechnic College was established in 1958 by Very Rev. Fr. Gilbert Palakunnel CMI, 
                and is managed by CMI Fathers - a priestly order reputed for their contributions in the field 
                of education and healthcare...
            </div>
            <span class="btn-icon">
                <fa name="angle-right"></fa>
            </span>
        </a>
    </div>
    <div class="col-xs-6 col-sm-4 sub-banner-col">
        <a [routerLink]="['/admissions']">
            <div class="img-wrap"><img src="/assets/images/admissions.png" alt/></div>
            <h6>ADMISSIONS</h6>
            <div *ngIf="index && !index.admissionBtns" class="description">
                <span [innerHtml]="index.subTextDefault"></span>...
            </div>
            <div *ngIf="index && index.admissionBtns" class="description">
                <span [innerHtml]="index.subTextAdmission"></span>...
            </div>
            <span class="btn-icon">
                <fa name="angle-right"></fa>
            </span>
        </a>
    </div>
    <div class="col-xs-6 col-sm-4 sub-banner-col">
        <a [routerLink]="['/campus', 'facilities']">
            <div class="img-wrap"><img src="/assets/images/campus.png" alt/></div>
            <h6>CAMPUS</h6>
            <div class="description">
                Situated 8 KM south of Alappuzha town by the side of NH 66, Carmel occupies a unique position 
                in the field of technical education in the country. The management, by its bottom up 
                approach encourages...
            </div>
            <span class="btn-icon">
                <fa name="angle-right"></fa>
            </span>
        </a>
    </div>
</div>