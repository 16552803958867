import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myTabCountPipe'
})
export class TabCountPipe implements PipeTransform {
    public transform(value: number): string {
        if (value || value === 0) {
            return '(' + value + ')';
        }
        return '';
    }
}
