import { Component, Input, ElementRef, SimpleChanges } from '@angular/core';

@Component({
    selector: 'top-tab',
    templateUrl: './top-tab.component.html',
    styleUrls: ['./top-tab.component.css']
})
export class TopTabComponent {

  public action: string = 'default';
    @Input() public tabTitle: string;
    @Input() public active: boolean;
    @Input() public enabled: boolean = true;
    @Input() public tabLabel: string;
    @Input() public activeTab: TopTabComponent;

    constructor(private element: ElementRef) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.element && 'activeTab' in changes && this.activeTab && this.activeTab.tabTitle !== this.tabTitle) {
          this.element.nativeElement.style.display = 'none';
        } else if (this.element && 'activeTab' in changes && this.activeTab && this.activeTab.tabTitle === this.tabTitle) {
            this.element.nativeElement.style.display = 'block';
        }
      }
}
