
/*! ****************************************************************************
Author: Libu T.Babu, email: crazylibu@yahoo.com
***************************************************************************** */
import { Component, Input } from '@angular/core';

@Component({
    selector: 'image-thumbnail',
    templateUrl: './image-thumbnail.component.html',
    styleUrls: ['./image-thumbnail.component.css']
})
export class ImageThumbnailComponent {

    @Input() public mainTitle: string;
    @Input() public folder: string;
    @Input() public fileName: string;
    @Input() public type: string;
    @Input() public width: string = '150px';
    @Input() public height: string = 'auto';
    @Input() public transform: boolean = false;
}
