import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { HomeRoutes } from './home.routing';
import { HomeComponent } from './home';
import { ShortcutComponent } from './shortcut';
import { BannerComponent, BannerTextComponent } from './banner';
import { RecruitersComponent } from './recruiters';
import { SubBannerComponent } from './sub-banner';


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    SharedModule,
    RouterModule.forChild(HomeRoutes)
  ],
  declarations: [
    HomeComponent,
    ShortcutComponent,
    BannerComponent,
    RecruitersComponent,
    SubBannerComponent,
    BannerTextComponent
  ]
})
export class HomeModule {}
