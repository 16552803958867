<main-body [mainTitle]="selectedDepartment">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_{{departmentKey}}" type="pre_banner_sub"></preload-picture>
    </div>
    <loader [isLoaded]="isLoaded" [ngStyle]="{'height': isLoaded ? 'auto' : '200px'}">
        <top-tabs *ngIf="isLoaded" (activeTabEmitter)="setActiveTab($event)" [activateTab]="activateTab">
            <top-tab *ngIf="departmentDetails?.description && departmentDetails?.description.trim().length > 0" tabTitle="Programmes" [activeTab]="activeTab">
                <programmes [error]="error" [description]="departmentDetails.description" [department]="selectedDepartment" *ngIf="activeTab && activeTab.tabTitle === 'Programmes'"></programmes>
            </top-tab>
            <top-tab *ngIf="selectedDepartment === 'Civil Engineering' || selectedDepartment === 'Mechanical Engineering' || selectedDepartment === 'Electrical and Electronics Engineering' || selectedDepartment === 'Automobile Engineering' || selectedDepartment === 'Computer Engineering' || selectedDepartment === 'Electronics Engineering'"tabTitle="Syllabus" [activeTab]="activeTab">
                <syllabus [department]="selectedDepartment" *ngIf="activeTab?.tabTitle === 'Syllabus'"></syllabus>
            </top-tab>
            <top-tab *ngIf="departmentDetails?.workshops?.length > 0" tabTitle="Labs / Workshops" [activeTab]="activeTab">
                <labs [error]="error" [workshops]="departmentDetails.workshops" *ngIf="activeTab?.tabTitle === 'Labs / Workshops'"></labs>
            </top-tab>
            <top-tab *ngIf="departmentDetails?.aided?.length > 0" [tabTitle]="selectedDepartment === 'General Engineering' || selectedDepartment === 'Office Administration' || selectedDepartment === 'Workshop' ? 'Staff-Aided' : 'Faculty-Aided'" [activeTab]="activeTab">
                <faculty [error]="error" [tabTitle]="selectedDepartment === 'General Engineering' || selectedDepartment === 'Office Administration' || selectedDepartment === 'Workshop' ? 'Staff-Aided' : 'Faculty-Aided'" [staffs]="departmentDetails.aided" *ngIf="activeTab?.tabTitle === 'Faculty-Aided' || activeTab?.tabTitle === 'Staff-Aided'"></faculty>
            </top-tab>
            <top-tab *ngIf="departmentDetails?.selfFinancing?.length > 0" [tabTitle]="selectedDepartment === 'General Engineering' || selectedDepartment === 'Office Administration' || selectedDepartment === 'Workshop' ? 'Staff-Self Financing' : 'Faculty-Self Financing'" [activeTab]="activeTab">
                <faculty [department]="selectedDepartment" [error]="error" [tabTitle]="selectedDepartment === 'General Engineering' || selectedDepartment === 'Office Administration' || selectedDepartment === 'Workshop' ? 'Staff-Self Financing' : 'Faculty-Self Financing'" [staffs]="departmentDetails.selfFinancing" *ngIf="activeTab?.tabTitle === 'Faculty-Self Financing' || activeTab?.tabTitle === 'Staff-Self Financing'"></faculty>
            </top-tab>
            <top-tab *ngIf="departmentDetails?.activities?.length > 0" tabTitle="Activities" [activeTab]="activeTab">
                <activities [error]="error" [activities]="departmentDetails.activities" *ngIf="activeTab?.tabTitle === 'Activities'"></activities>
            </top-tab>
        </top-tabs>
    </loader>
</main-body>