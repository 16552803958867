import { Component, Input } from '@angular/core';
import { Message } from '@app/core';

@Component({
    selector: 'programmes',
    templateUrl: './programmes.component.html',
    styleUrls: ['./programmes.component.css']
})
export class ProgrammesComponent {

    @Input() public department: string;
    @Input() public error: Message;
    @Input() public description: string;
}