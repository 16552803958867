import { Component, Input } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';

@Component({
    selector: 'social-link',
    templateUrl: './social-link.component.html',
    styleUrls: ['./social-link.component.css'],
    animations: [
        trigger('slideInOut', [
            state('in', style({right: '0px'})),
            state('out', style({right: '-40px'})),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ])
    ]
})
export class SocialLinkComponent {

    @Input() public position: string = 'out';

    public slide(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.position = this.position === 'in' ? 'out' : 'in';
    }
 }
