import { Routes } from '@angular/router';

import { AboutUsComponent } from '@app/about-us-module/about-us/about-us.component';

export const AboutUsRoutes: Routes = [
  {
    path: '',
    component: AboutUsComponent,
    data: {
      title: 'About Us'
    }
  }
];
