import { Routes } from '@angular/router';

import { HomeComponent } from '@app/home-module/home/home.component';

export const HomeRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Home'
    }
  },
  {
    path: '/home',
    component: HomeComponent,
    data: {
      title: 'Home'
    }
  }
];
