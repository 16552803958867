
/*! ****************************************************************************
Author: Libu T.Babu, email: crazylibu@yahoo.com
***************************************************************************** */
import { Component, Input } from '@angular/core';

@Component({
    selector: 'box-panel',
    templateUrl: './box-panel.component.html',
    styleUrls: ['./box-panel.component.css']
})
export class BoxPanelComponent {

    @Input() public boxMainTitle: string;
    @Input() public boxSubTitle: string;
}
