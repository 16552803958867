import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';

const browserWindow: any = window || {};
if (browserWindow && browserWindow['__env'] && browserWindow['__env']['ENV'] === 'PRODUCTION') {
  console.log('--- Aviratha is running in Production Mode ---');
  enableProdMode();
  if (window) {
    window.console.log = function() {};
  }
  /**
   * To enable PWA Service worker uncomment this code
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('ngsw-worker.js');
      });
    }*/
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.log(err));
