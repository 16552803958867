import { Routes } from '@angular/router';

import { QuickLinksComponent } from '@app/quick-links-module/quick-links/quick-links.component';
export const QuickLinksRoutes: Routes = [
  {
    path: '',
    component: QuickLinksComponent,
    data: {
      title: ''
    }
  }
];
