import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RoutingService, DataService } from './core';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public route: string;
  public links: any[] = [];
  private breadcrumbSubs: any;
  private routerSubs: any;
  private indexRouteSubs: any;

  constructor(private router: Router,
    private routingService: RoutingService,
    private dataService: DataService) {
    
    this.dataService.updateBannerIndex();
    this.routerSubs = this.router.events.subscribe((value: any) => {
      this.route = null;
      if (value && value.routerEvent) {
        this.route = value.routerEvent.url;
      }
      if (value instanceof NavigationEnd) {
        this.routingService.setBreadcrumbAndNav(value.url);
      }
    });

    this.breadcrumbSubs = this.routingService.breadcrumbEmitter
      .subscribe((links: any) => {
          this.links = links;
      });

    this.indexRouteSubs = this.routingService.indexRouteEmitter
      .subscribe((route: string) => {
          this.route = route;
      });
  }

  public ngOnDestroy(): void {
    if (this.breadcrumbSubs) {
      this.breadcrumbSubs.unsubscribe();
    }
    if (this.routerSubs) {
      this.routerSubs.unsubscribe();
    }
    if (this.indexRouteSubs) {
      this.indexRouteSubs.unsubscribe();
    }
  }

  public onActivate(event: Event): void {
    window.scroll(0,0);
  }
}
