<main-body mainTitle="Activities" *ngIf="route === '/campus/activities'">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_activities" type="pre_banner_sub"></preload-picture>
    </div>
    <sub-body subTitle="National Cadet Corps (N.C.C.)">
        The N.C.C. helps to build up the character, self confidence, personality and leadership quality 
        of a student. B and C certificate holders are given preference in military, paramilitary and 
        public sector undertakings for employment.
        <span class="bold bottom">N.C.C. Officer: Mr. Anish</span>
    </sub-body>
    <sub-body subTitle="National Service Scheme (N.S.S.)">
        National Service Scheme (N.S.S.) is a voluntary organization under the Ministry of Human Resources 
        Development, Government of India intended to develop the personality of students through community 
        service. The regular activities of N.S.S. comprises of the following:
        <ul class="outer-ul">
            <li>Educational Programmes</li>
            <li>Health Programmes</li>
            <li>Self Employment Programmes</li>
            <li>Agricultural Programmes</li>
            <li>Orientation Courses</li>
            <li>Personality Development Programmes</li>
            <li>Campus Work</li>
            <li>Camping etc.</li>
        </ul>
        <div class="bold">Programme Officer: Mrs. Tinu Scaria, M.Tech.</div>
        <div class="bold">Associate Programme Officer: Mr. Bibin K.W., I.T.I.</div>
        <div class="bold">Associate Programme Officer: Mr. Jobin George A.E., D.A.E.</div>
    </sub-body>
    <sub-body subTitle="Sports & Games">
        The Physical Education Department under the leadership of Mr. Jaick Joseph, B.P.Ed. is active, right from 
        the beginning of the academic year.
    </sub-body>
    <sub-body subTitle="Community Polytechnic Scheme">
        The community polytechnic scheme was implemented in the year 1986. The scheme is sponsored by MHRD, 
        Govt. of India, for the integral development of rural areas utilizing the facilities and expertise 
        of the college. In addition to the main center in the campus, CPS has got 5 extension centers at 
        St. Mary’s Mazarello Convent Charamangalam, Carmellite convent Muhamma, Carmel Bhavan convent 
        Poonthoppu, Mount Carmel Bhavan Convent Vadackal and Lissuex F.C Convent Kondackal in Alappuzha District.<br/><br/>
        We conduct free training courses in Plumbing, Arc Welding, Wiring, Petrol and Diesel Engine Mechanism, 
        Turning, Machinist, Radio, T.V and VCD repairing etc. for boys and Tailoring , Machine Embroidery, DTP, 
        Fabric Painting, Coir Mat Making etc for girls. About 1000 people are trained in every year in different 
        trades. We have also transferred the technologies for Azolla cultivation, Vermi compost, Rainwater 
        harvesting and Bio-gas plants. A number of awareness programmes, medical camps, blood camps detecting 
        groups, technical servicing camps etc were also conducted. In the previous year in co-operation with 
        FIRMA we conducted an awareness programme on the necessity of planting mangroves in the seashore and 
        planted about 12000 plants with the help of the villagers in the coastal belt of Punnapra village.<br/><br/>
        <span class="box-title1">Extension Centres</span><br/>
        <div class="row extension-row">
            <div class="col-12 col-sm-6 col-md-4 extension-wrap">
                <box-panel class="extension">
                    <span class="box-title2">Charamangalam Extention Centre</span><br/>
                    We conduct free courses in Tailoring, Machine Embroidery, Fabric Painting and Flower Making for girls 
                    and housewives in this center. We were also transferred the technology of vermin compost to this center.
                </box-panel>
            </div>
            <div class="col-12 col-sm-6 col-md-4 extension-wrap">
                <box-panel class="extension">
                    <span class="box-title2">Muhamma Extention Centre</span><br/>
                    CPS is conducting free courses in Tailoring, Machine Embroidery, DTP, Fabric Painting and Flower 
                    making for girls and telephone repairing for boys in this centre.
                </box-panel>
            </div>
            <div class="col-12 col-sm-6 col-md-4 extension-wrap">
                <box-panel class="extension">
                    <span class="box-title2">Poonthoppu Extention Centre</span><br/>
                    We are conducting free courses in Tailoring, Machine Embroidery, Fabric Painting etc for girls in this 
                    centre. Now we are planning to conduct Azolla cultivation unit and vermin compost unit at this centre.
                </box-panel>
            </div>
            <div class="col-12 col-sm-6 col-md-4 extension-wrap">
                <box-panel class="extension">
                    <span class="box-title2">Vadackal Extention Centre</span><br/>
                    CPS is conducting free courses in Tailoring, Machine Embroidery, Fabric Painting and Garment Making 
                    for girls and housewives in this center.
                </box-panel>
            </div>
            <div class="col-12 col-sm-12 col-md-8 extension-wrap">
                <box-panel class="extension">
                    <span class="box-title2">Kondackal Extention Centre</span><br/>
                    We are conducting free courses in Tailoring, DTP, Fabric Painting and Flower making for girls and 
                    housewives in center. We are also conducting Coir Mat making and Mechanized Coir spinning with the 
                    help of an advanced trainer from coir board. Coir board gives stipend to the trainees of coir mat 
                    making and mechanized coir spinning. Many ladies get trained here. In this year we are planning to 
                    transfer the technologies of Azolla cultivation, Rain Water Harvesting etc to this center.
                </box-panel>
            </div>
        </div>
        <span class="box-title1 last">Support Services</span><br/>
        We are conducting different awareness pragrammes in the main center and also in the extension centers. 
        In the previous tear we conducted an awareness programme on 'How to get loans' and the classes were taken 
        by the district industrial officer.<br/><br/>
        We have also conducted an advanced training programmes, a two-day workshop on fabric painting and glass 
        painting last year. The instructors from Camlin internationals were taken the classes.<br/>
        Technical service camps are also conducting by CPS. In this camps electric and electronic home 
        appliances were servicing free of cost. Classes on Mental Health, Personality Development, Social 
        Problems etc were conducted by CPS.A medical camp and a blood group detection camp were conducted 
        in the previous year. Doctors from T.D. Medical College, Alappuzha participated in the camps. About 
        200 people were given treatment at this camp.<br/><br/>
        The main aim of community polytechnic scheme is the development of the rural population. Especially 
        the younger generation who could not complete their school education and having no idea about framing 
        their life. Along with other activities such as Transfer of Technology, Support Service, the CPS gives 
        much importance to Man Power Development programmes.
    </sub-body>
    <sub-body subTitle="Chavara Cell- (Placement, Guidance and Counselling)">
        Chavara Cell is functioning on the campus for the placement, career guidance and counselling 
        of the students. The objectives of Chavara Cell are following:
        <ul class="outer-ul">
            <li>To liaison between potential employers and students to offer Job placements for students.</li>
            <li>To conduct seminars and contact classes with regard to higher study options and technological developments.</li>
            <li>Holistic formation of students through personality development programmes and counselling.</li>
            <li>To arrange campus interviews.</li>
        </ul>
        <div class="bold">Placement Officer: Mrs. Sreeji Srinivas, M.Tech.</div>
        <div class="bold">Associate Placement Officer: Mr. Sudheer Babu P., D.E.C.</div>
    </sub-body>
    <sub-body subTitle="Continuing Education Cell (C.E.C.)">
        <div class="body-info">Reg. No. CE/P – 137/2002 (Sub-Centre of Centre for Continuing Education Kerala – Established by the Government of Kerala)</div>
        <span class="box-title2">Provides Quality Programs for Enhanced Skills, Career Advancement and Confidence for Jobs Abroad.</span><br/>
        Continuing Education is thought of as courses of programme which include a wide variety of topics or 
        subjects which learners would undergo to develop a new skill or enhance their knowledge in a particular 
        area. This course leads to an accredited Certificate, Diploma or Degree. The learner will obtain a 
        Diploma from the institution stating the level of competency or skill he may have obtained by undergoing 
        the course or a simple certificate which says that he/she has participated in the programme. The PGDCA 
        examination is conducted by the State Board of Technical Education.
        <span class="bold bottom">Manager: Fr Jacob Kurian CMI, M.Tech</span>
        <span class="bold">Clerk: Mrs. Gracy Alexander</span>
    </sub-body>
    <sub-body subTitle="Parent - Teacher Association">
        A Parent - Teacher Association is working in the College. Creative suggestions from the parents are 
        welcomed for the smooth functioning of the college. The parents may have periodic check up of 
        curricular and co-curricular activities of their wards by contacting the Principal and teachers. 
        The P.T.A. strives to ensure the participation of the parents in the upkeep of good academic 
        environment and campus discipline.
        <span class="bold bottom">Secretary: Fr. James Devasia CMI, M.Tech., Principal</span>
    </sub-body>
</main-body>
<main-body mainTitle="Facilities" *ngIf="route === '/campus/facilities'">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_facilities" type="pre_banner_sub"></preload-picture>
    </div>
    Situated 8 KM south of Alappuzha town by the side of NH 66, Carmel occupies a unique position in the field of 
    technical education in the country. The management, by its bottom up approach encourages everybody in the 
    campus to put his best foot forward always.
    <div class="row">
        <div class="col-6 col-sm-4 facitity">
            <sub-body subTitle="Hostel">
                <image-thumbnail [transform]="true" width="140px" folder="/assets/images" fileName="hostel" type="pre_facility" mainTitle="Hostel"></image-thumbnail>
                The Polytechnic has two separate hostels, one for the boys and the other for the girls. Warden of the boys' hostel 
                is Fr. Thomas Pockavaraythu CMI. The Boys' Hostel accommodates 
                200 students. Matron of the Ladies' Hostel is Mrs. Neethu Fernandez.
            </sub-body>
        </div>
        <div class="col-6 col-sm-4 facitity">
            <sub-body subTitle="Library">
                <image-thumbnail [transform]="true" width="140px" folder="/assets/images" fileName="library" type="pre_facility" mainTitle="Library"></image-thumbnail>
                The Polytechnic has a well furnished computerized Library and Reading room with thousands of books and journals 
                from both inside and outside the country caters the needs of our academic community. Mr. Shijo Cherian is the Librarian.
            </sub-body>
        </div>
        <div class="col-6 col-sm-4 facitity">
            <sub-body subTitle="Cool Bay (Canteen)">
                <image-thumbnail [transform]="true" width="140px" folder="/assets/images" fileName="canteen" type="pre_facility" mainTitle="Cool Bay (Canteen)"></image-thumbnail>
            </sub-body>
        </div>
    </div>
</main-body>