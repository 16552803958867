import { Routes } from '@angular/router';

import { DepartmentsTabPanelComponent } from '@app/department-module/departments-tab-panel/departments-tab-panel.component';
export const DepartmentRoutes: Routes = [
  {
    path: '',
    component: DepartmentsTabPanelComponent,
    data: {
      title: ''
    }
  }
];
