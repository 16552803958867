/*! *********************************************
 Author: Libu T.Babu, email: crazylibu@yahoo.com
*********************************************** */
import { Component, Input, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { Message } from '@app/core';

@Component({
    selector: 'message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnChanges {

    @Input() public type: 'Error' | 'Success' | 'Warning' | 'Note';
    @Input() public message: Message;
    @Input() public maxWidth: string = 'auto';

    constructor(private element: ElementRef) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if ('maxWidth' in changes && this.maxWidth) {
            this.element.nativeElement.style.maxWidth = this.maxWidth;
        }
    }
}
