<header>
    <div class="row outer-row header-top">
        <div class="col-12 col-sm-6 header-top-col">
            <span><fa name="phone"></fa>{{index.phoneNumber}}</span>
            <span><fa name="envelope-o"></fa>{{index.emailId}}</span>
        </div>
        <div class="col-xs-12 col-sm-6 header-top-col right">
            <a href="{{appEndpoint}}/staff/sign-in" target="_blank">STAFF SIGN IN</a>
        </div>
    </div>
    <div class="row outer-row">
        <div class="col-12 menu-wrapper">
            <a [routerLink]="['/index']" title="Carmel Polytechnic College" class="title-outer">
                <div class="logo">
                    <img src="/assets/images/logo_main.png" alt/>
                </div>
                <div class="title-wrapper">
                    <div class="title">CARMEL</div>
                    <div class="sub-title">POLYTECHNIC COLLEGE</div>
                    <div class="address">PUNNAPRA - ALAPPUZHA</div>
                </div>
            </a>
            <div class="nav-outer">
                <nav>
                    <div class="portal-nav-outer" [@slideUpDown]="showNav">
                        <div class="portal-nav-wrapper">
                            <a class="admin" [ngClass]="{active: nav && nav.main === 'Home'}" [routerLink]="['/index']">
                                <span>
                                    <span class="active-indicator"></span>
                                    Home
                                </span>
                            </a>
                            <a class="admin" [ngClass]="{active: nav && nav.main === 'About Us'}" [routerLink]="['/about-us']">
                                <span>
                                    <span class="active-indicator"></span>
                                    About Us
                                </span>
                            </a>
                            <a class="admin" [ngClass]="{active: nav && nav.main === 'Admissions'}" [routerLink]="['/admissions']">
                                <span>
                                    <span class="active-indicator"></span>
                                    Admissions
                                </span>
                            </a>
                            <a class="nav-group admin" [ngClass]="{active: (nav && nav.main === 'Departments') || navMenuState.departmentsMenu === 'down'}"
                                (click)="toggleSubmenu($event, 'departmentsMenu')">
                                <span>
                                    <span class="active-indicator"></span>
                                    Departments
                                    <span class="caret" [ngClass]="{'rotate-180': navMenuState.departmentsMenu === 'down'}"><fa name="caret-down"></fa></span>
                                </span>
                                <span class="toggle-icon">
                                    <span class="ico">{{navMenuState.departmentsMenu === 'up' ? '+' : '-'}}</span>
                                </span>
                                <div class="subnav-wrap" [@subnavGroupToggle]="navMenuState.departmentsMenu">
                                    <ul class="subnavs">
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Civil Engineering'}" [routerLink]="['/departments/civil-engineering']">
                                                Civil Engineering
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Mechanical Engineering'}" [routerLink]="['/departments', 'mechanical-engineering']">
                                                Mechanical Engineering
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Electrical and Electronics Engineering'}" [routerLink]="['/departments', 'electrical-and-electronics-engineering']">
                                                Electrical and Electronics Engineering
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Automobile Engineering'}" [routerLink]="['/departments', 'automobile-engineering']">
                                                Automobile Engineering
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Computer Engineering'}" [routerLink]="['/departments', 'computer-engineering']">
                                                Computer Engineering
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Electronics Engineering'}" [routerLink]="['/departments', 'electronics-engineering']">
                                                Electronics Engineering
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'General Engineering'}" [routerLink]="['/departments', 'general-engineering']">
                                                General Engineering
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Workshop'}" [routerLink]="['/departments', 'workshop']">
                                                Workshop
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Office Administration'}" [routerLink]="['/departments', 'office-administration']">
                                                Office Administration
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </a>
                            <a class="nav-group admin" [ngClass]="{active: (nav && nav.main === 'Campus') || navMenuState.campusMenu === 'down'}"
                                (click)="toggleSubmenu($event, 'campusMenu')">
                                <span>
                                    <span class="active-indicator"></span>
                                    Campus
                                    <span class="caret" [ngClass]="{'rotate-180': navMenuState.campusMenu === 'down'}"><fa name="caret-down"></fa></span>
                                </span>
                                <span class="toggle-icon">
                                    <span class="ico">{{navMenuState.campusMenu === 'up' ? '+' : '-'}}</span>
                                </span>
                                <div class="subnav-wrap" [@subnavGroupToggle]="navMenuState.campusMenu">
                                    <ul class="subnavs">
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Facilities'}" [routerLink]="['/campus', 'facilities']">
                                                Facilities
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Activities'}" [routerLink]="['/campus', 'activities']">
                                                Activities
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </a>
                            <a class="nav-group admin" [ngClass]="{active: (nav && nav.main === 'Library') || navMenuState.libraryMenu === 'down'}"
                                (click)="toggleSubmenu($event, 'libraryMenu')">
                                <span>
                                    <span class="active-indicator"></span>
                                    Library
                                    <span class="caret" [ngClass]="{'rotate-180': navMenuState.libraryMenu === 'down'}"><fa name="caret-down"></fa></span>
                                </span>
                                <span class="toggle-icon">
                                    <span class="ico">{{navMenuState.libraryMenu === 'up' ? '+' : '-'}}</span>
                                </span>
                                <div class="subnav-wrap" [@subnavGroupToggle]="navMenuState.libraryMenu">
                                    <ul class="subnavs">
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'LMS'}" href="https://moodle.carmelpoly.in/login/index.php" target="_blank">
                                                LMS
                                            </a>
                                        </li>
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Carmel Data Centre'}" href="https://nba.carmelpoly.in" target="_blank">
                                                Carmel Data Centre
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </a>
                            <a class="nav-group admin" [ngClass]="{active: (nav && nav.main === 'Fee') || navMenuState.feeMenu === 'down'}"
                                (click)="toggleSubmenu($event, 'feeMenu')">
                                <span>
                                    <span class="active-indicator"></span>
                                    Fee
                                    <span class="caret" [ngClass]="{'rotate-180': navMenuState.feeMenu === 'down'}"><fa name="caret-down"></fa></span>
                                </span>
                                <span class="toggle-icon">
                                    <span class="ico">{{navMenuState.feeMenu === 'up' ? '+' : '-'}}</span>
                                </span>
                                <div class="subnav-wrap" [@subnavGroupToggle]="navMenuState.feeMenu">
                                    <ul class="subnavs">
                                        <li>
                                            <a [ngClass]="{active: nav && nav.sub === 'Pay Online'}" href="https://paydirect.eduqfix.com/app/pDLKjv890e9C7JJO7lFy48t46jszRZdQNjam0ONL899cRUw51zOGe+TM23UqdJdi/6530" target="_blank">
                                                Pay Online
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </a>
                            <!-- <a class="admin" [ngClass]="{active: nav && nav.main === 'Alumni'}" [routerLink]="['/alumni']">
                                <span>
                                    <span class="active-indicator"></span>
                                    Alumni
                                </span>
                            </a> -->
                            <a class="admin" [ngClass]="{active: nav && nav.main === 'Contact Us'}" [routerLink]="['/contact-us']">
                                <span>
                                    <span class="active-indicator"></span>
                                    Contact Us
                                </span>
                            </a>
                        </div>
                    </div>
                    <div class="portal-nav-icon" [ngClass]="{'navicon-active': showNav === 'down'}" (click)="toggleNavs($event)">
                        <i class="fa fa-navicon"></i>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>