import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myFileNamePipe'
})
export class FileNamePipe implements PipeTransform {
    public transform(name: string): string {
        if (!name) {
            return null;
        }
        name = name.split('. ').join(' ');
        name = name.split(', ').join(' ');
        name = name.split(' - ').join(' ');
        name = name.split(' & ').join(' ');
        name = name.split('-').join(' ');
        name = name.split('.').join(' ');
        name = name.split(',').join(' ');
        name = name.split('&').join(' ');
        while (name.indexOf('  ') >= 0) {
            name = name.split('  ').join(' ');
        }
        return name.trim().split(' ').join('_').toLowerCase();
    }
}
