/*! *********************************************
 Author: Libu T.Babu, email: crazylibu@yahoo.com
*********************************************** */
import { Component, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService } from '@app/env.service';

@Component({
    selector: 'banner-text',
    templateUrl: './banner-text.component.html',
    styleUrls: ['./banner-text.component.css']
})
export class BannerTextComponent {

    public user: any;
    @Input() public index: any = {};
    public admissionsEndpoint: string;
    
    constructor(
        private env: EnvService,
        private router: Router) {
            this.admissionsEndpoint = env.ADMISSIONS_ENDPOINT;
        }

    public showView(event: Event, view: string): void {
        this.router.navigate([view]);
    }
}
