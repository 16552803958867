import { Component, OnDestroy } from '@angular/core';
import { RoutingService, getDepartment, DataService, Message, getError, getSubjectKey } from '@app/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TopTabComponent } from '@app/shared/components/top-tabs';

@Component({
    selector: 'departments-tab-panel',
    templateUrl: './departments-tab-panel.component.html',
    styleUrls: ['./departments-tab-panel.component.css']
})
export class DepartmentsTabPanelComponent implements OnDestroy {

    public selectedDepartment: string;
    public departmentKey: string = 'civil_engineering';
    public isLoaded: boolean = false;
    public error: Message;
    public activeTab: TopTabComponent;
    public activateTab: string;
    public departmentDetails: any = {};
    private routerSubs: any;
    
    constructor(
        private router: Router,
        private dataService: DataService,
        private routingService: RoutingService,
        private activatedRoute: ActivatedRoute) {

        this.routerSubs = this.router.events.subscribe((value: any) => {
            if (value instanceof NavigationEnd) {
                this.activatedRoute.params
                    .subscribe((params: any) => {
                        this.routingService.setBreadcrumbAndNav(value.url, params);
                        let selectedDepartment: string = getDepartment(params['department']);
                        if (this.selectedDepartment !== selectedDepartment) {
                            this.selectedDepartment = selectedDepartment;
                            this.departmentKey = getSubjectKey(this.selectedDepartment);
                            this.fetchDepartment();
                        }
                    });
            }
        });
    }
  
    public setActiveTab(event: TopTabComponent): void {
      this.activeTab = event;
    }

    public ngOnDestroy(): void {
        if (this.routerSubs) {
          this.routerSubs.unsubscribe();
        }
    }

    private fetchDepartment(): void {
        this.dataService.get('/web/content/department-details', true, {department: this.selectedDepartment})
            .subscribe((info: any) => {
                this.departmentDetails = info.map.OBJECT ? info.map.OBJECT : {};
                this.isLoaded = true;
            },
            (err: any) => {
                this.isLoaded = true;
                this.error = getError(err, 'DepartmentComponent');
            });        
    }
}