/*! *********************************************
 Author: Libu T.Babu, email: crazylibu@yahoo.com
*********************************************** */
export class Application {
    public id: number = null;
    public applicationNumber: string = null;
    public candidateProfile: any = null;
    public branchPreference: any = null;
    public academics: any = null;
    public validFields: any = {};
    public apply: boolean = false;
    public applied: boolean = false;
}
