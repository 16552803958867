<!-- <a class="banner-pic" target="_blank" href="https://forms.gle/7e3wknnjyH7eWCg59">
    <div class="pic-border">
        <img src="/assets/images/naipunyam.png"/>
    </div>
</a>
<div class="btn-row" *ngIf="index.readMoreBtn">
    <a target="_blank" href="https://forms.gle/7e3wknnjyH7eWCg59" class="btn-pri btn-banner" title="Register Now">
        Register Now
    </a>
</div> -->
<div class="outer-wrapper">
    <div class="main-content-wrapper">
        <div class="logo-65">
            <img src="/assets/images/65_logo.png"/>
        </div>
        <ng-template [ngIf]="index && index.admissionBtns">
            <div class="main-title">
                <span class="left">
                    Admissions
                </span>
                <span class="separator">
                    |
                </span>
                <span class="right">
                    {{null | myAdmissionYearPipe}}
                </span>
            </div>
            <div class="main-text">
                <span [innerHtml]="index.mainTextAdmission"></span>...
            </div>
            <div class="btn-row">
                <a [routerLink]="[index.readMoreBtnLink]" class="btn-pri btn-banner read-more" title="Read More">
                    Read More
                </a>
                <a href="{{admissionsEndpoint}}/web/apply" target="_blank" *ngIf="index.applyRegularBtn && (!user || user.userType !== 'Candidate')" class="btn-pri btn-purple-transparent" title="Apply Now">
                    Apply - Regular
                </a>
                <a href="{{admissionsEndpoint}}/web/apply/lateral-entry" target="_blank" *ngIf="index.applyLateralBtn && (!user || user.userType !== 'Candidate')" class="btn-pri btn-green-transparent" title="Apply Now">
                    Apply - Lateral Entry
                </a>
                <!-- <br/> -->
                <!-- check under which conditions this is on -->
                <a href="{{admissionsEndpoint}}/web/sign-in" target="_blank" class="btn-pri btn-purple-link" title="Already applied">
                    Already applied?
                </a>
                <a *ngIf="(index.applyRegularBtn || index.applyLateralBtn) && (!user || user.userType !== 'Candidate')" href="https://forms.gle/yJRff7y1hptdck3d8" target="_blank" class="btn-pri btn-purple-link margin-left-15" title="Admission Enquiry">
                    Admission Enquiry
                </a>
            </div>
        </ng-template>
        <ng-template [ngIf]="index && !index.admissionBtns">
            <div class="main-title">
                <span class="left">
                    {{index.titleLeft}}
                </span>
                <span *ngIf="index.titleRight" class="separator">
                    |
                </span>
                <span *ngIf="index.titleRight"class="right">
                    {{index.titleRight}}
                </span>
            </div>
            <div class="main-text">
                <span [innerHtml]="index.mainTextDefault"></span>
            </div>
            <div class="btn-row" *ngIf="index.readMoreBtn">
                <a [routerLink]="[index.readMoreBtnLink]" class="btn-pri btn-banner" title="Read More">
                    Read More
                </a>
                <!-- Remove this Admission enquiry if not needed -->
                <a href="https://forms.gle/yJRff7y1hptdck3d8" target="_blank" class="btn-pri btn-purple-link margin-left-15" title="Admission Enquiry">
                    Admission Enquiry
                </a>
            </div>
        </ng-template>
    </div>
</div>