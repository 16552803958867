<main-body mainTitle="Admissions {{null | myAdmissionYearPipe}}">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_admissions" type="pre_banner_sub"></preload-picture>
    </div>      
    <div class="row adm-row">
        <div class="col-12 desc-col">
            <span *ngIf="admissions && !admissions.admissionBtns" [innerHtml]="admissions.descriptionDefault"></span>
            <span *ngIf="admissions && admissions.admissionBtns" [innerHtml]="admissions.descriptionAdmission"></span>
        </div>
        <div *ngIf="admissions.applyRegularBtn" class="col-sm-6 adm-reg-col">
            <sub-body subTitle="Admissions - Regular">
                <a title="Prospectus | Regular" class="btn btn-pri btn-grey-transparent" href="/assets/documents/prospectus-regular-2024-2025.pdf" download>
                    <fa name="download" class="fa-left"></fa>
                    Prospectus
                </a>
                <a *ngIf="admissions.admissionBtns && (!user || user.userType !== 'Candidate')" title="Apply | Regular" class="btn btn-pri btn-purple" href="{{admissionsEndpoint}}/web/apply" target="_blank">
                    <fa name="check-circle" class="fa-left"></fa>
                    Apply
                </a>
                <!-- Comment out following line after admission closed -->
                <!-- <a href="https://forms.gle/yJRff7y1hptdck3d8" target="_blank" title="Admission Enquiry">Admission Enquiry</a> -->
                <br/>
                <a class="margin-top-10" href="/assets/documents/admission-dates-regular-2024-2025.pdf" title="Important Admission Dates" download>Important Admission Dates</a>
            </sub-body>
        </div>
        <div *ngIf="admissions.applyLateralBtn" class="col-sm-6 adm-lat-col">
            <sub-body subTitle="Admissions - Lateral Entry">
                <a title="Prospectus | Lateral Entry" class="btn btn-pri btn-grey-transparent" href="/assets/documents/prospectus-lateral-entry-2024-2025.pdf" download>
                    <fa name="download" class="fa-left"></fa>
                    Prospectus
                </a>
                <a *ngIf="admissions.admissionBtns && (!user || user.userType !== 'Candidate')" title="Apply | Lateral Entry" class="btn btn-pri btn-green" href="{{admissionsEndpoint}}/web/apply/lateral-entry" target="_blank">
                    <fa name="check-circle" class="fa-left"></fa>
                    Apply
                </a>
                <br/>
                <a class="margin-top-10" href="/assets/documents/admission-dates-lateral-entry-2024-2025.pdf" title="Important Admission Dates" download>Important Admission Dates</a>
            </sub-body>
        </div>
    </div>
    <sub-body subTitle="Instructions for submission of online application">
        <ul class="outer-ul">
            <li>
                All applicants are required to apply through 
                <a href="http://polyadmission.org" target="_blank">http://polyadmission.org</a>
            </li>
            <li>
                Application fee of 
                <span class="bold">Rs.200/- (Rupees Two Hundred only)</span> 
                should be remitted for <span class="bold">Management Merit</span> seat and <span class="bold">Rs.500/- (Rupees Five Hundred only)</span> should be remitted for <span class="bold">Management Quota</span> seat to any of the branches of <span class="bold">The Federal Bank</span> or <span class="bold">UPI transaction</span> using any of the given Mobile Applications and provide the details of fee remitted in the application. Bank account / UPI payment details are given below.<br/>
                <div class="row bank-app-row">
                    <div class="col-12 col-sm-6 bank-col">
                        <ul>
                            <li>Account Name: <span class="bold">Carmel Polytechnic College.</span></li>
                            <li>Bank Name: <span class="bold">The Federal Bank Private Limited</span>, Carmel Extension Centre, Punnapra, Alappuzha.</li>
                            <li>Account Number: <span class="bold">22560100004081</span></li>
                            <li>IFSC: <span class="bold">FDRL0001567</span></li>
                        </ul>
                    </div>
                    <div class="col-12 col-sm-6 app-col">
                        <div class="upi">
                            <h3>UPI</h3>
                            <h3>CARMELPOLY@FBL</h3>
                            <h6><u>Use only Apps</u>: LOTZA, Google Pay, PhonePe, etc.</h6>
                        </div>
                    </div>
                </div>                 
            </li>
            <li>
                Payment can be done via challan at Federal Bank Carmel Extension Centre, Punnapra, 
                Alappuzha and for outstation students, payment can be done through <span class="bold">DD</span> 
                favoring <span class="bold">CARMEL POLYTECHNIC COLLEGE</span> payable at 
                <span class="bold">Alappuzha</span>.
            </li>
        </ul>
    </sub-body>
    <sub-body subTitle="At the time of Admission">
        <div class="docs">Documents of candidate to be submitted</div>
        <ul class="outer-ul">
            <li>
                Original and 3 copies of Qualifying Examination Certificate (SSLC / CBSE / ICSE / THSLC)
            </li>
            <li>
                Undertaking Certificate if Qualifying Examination is CBSE
            </li>
            <li>
                Transfer Certificate (TC)
            </li>
            <li>
                Conduct Certificate (CC)
            </li>
            <li>
                3 Passport size Photos
            </li>
            <li>
                Community Certificate in case of reservation seat
            </li>
        </ul>
        <!-- <div class="docs">Automobile Engineering</div>
        <ul class="docs-ul">
            <li>
                Date of Admission: <span class="bold">03/11/2020 Tuesday</span>
            </li>
            <li>
                Fee: Rs. 42,000/- (Rs. 15,000 Refundable). Online payment is preferable
            </li>
        </ul>
        <div class="docs">Computer Engineering</div>
        <ul class="docs-ul">
            <li>
                Date of Admission: <span class="bold">04/11/2020 Wednesday</span>
            </li>
            <li>
                Fee: Rs. 40,750/- (Rs. 15,000 Refundable). Online payment is preferable
            </li>
        </ul>
        <div class="docs">Electronics Engineering</div>
        <ul class="docs-ul">
            <li>
                Date of Admission: <span class="bold">04/11/2020 Wednesday</span>
            </li>
            <li>
                Fee: Rs. 40,750/- (Rs. 15,000 Refundable). Online payment is preferable
            </li>
        </ul> -->
        <div class="body-info">For more information about admission, please contact 
            our <span class="bold">College Office</span> at <span class="bold">0477 2288 825</span>, <span class="bold">Admission Cell</span> at <span class="bold">80783 25976</span> or submit <a href="https://forms.gle/yJRff7y1hptdck3d8" target="_blank" title="Admission Enquiry">Admission Enquiry</a> form.
        </div>
    </sub-body>
</main-body>