import { Component } from '@angular/core';
import { DataService, getErrorMessage } from '@app/core';
import { EnvService } from '@app/env.service';

@Component({
    selector: 'admissions',
    templateUrl: './admissions.component.html',
    styleUrls: ['./admissions.component.css']
})
export class AdmissionsComponent {

    public user: any;
    public admissions: any = {};
    public admissionsEndpoint: string;
    
    constructor(private dataService: DataService,
        private env: EnvService) {
            
        this.admissionsEndpoint = env.ADMISSIONS_ENDPOINT;
        this.fetchAdmissionSettings();
    }

    private fetchAdmissionSettings(): void {
        this.dataService.get('/web/content/admissions', true, null)
            .subscribe((info: any) => {
                this.admissions = info.map.OBJECT ? info.map.OBJECT : {};
            },
            (err: any) => {
                getErrorMessage(err, 'AdmissionsComponent');
            });        
    }
}