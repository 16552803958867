import { Component, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { clearTimers } from '@app/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'syllabus',
    templateUrl: './syllabus.component.html',
    styleUrls: ['./syllabus.component.css']
})
export class SyllabusComponent implements OnChanges, OnDestroy {

    @Input() public department: string;
    public sessionUrl: SafeResourceUrl = null;
    public url: SafeResourceUrl = null;
    public height: string = '2595px';
    public show: boolean = false;
    private timers: number[] = [];

    constructor(private sanitizer: DomSanitizer) {
        this.sessionUrl = this.sanitizer.bypassSecurityTrustResourceUrl('http://sitttrkerala.ac.in/index.php?r=site%2Fdiploma-syllabus&scheme=REV2015');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('department' in changes && this.department) {
            this.show = false;
            let timer: any = setTimeout(() => {
                this.loadUrl();
            }, 500);
            this.timers.push(timer);
        }
    }

    public ngOnDestroy(): void {
        clearTimers(this.timers);
    }

    private loadUrl(): void {
        this.show = false;
        this.height = '2595px';
        this.url = null;
        switch (this.department.toLowerCase()) {
            case 'civil engineering': this.url = this.sanitizer.bypassSecurityTrustResourceUrl('http://sitttrkerala.ac.in/index.php?r=site%2Fdiploma-syllabus-courses&prog=CE');
            this.height = '2630px';
            break;
            case 'mechanical engineering': this.url = this.sanitizer.bypassSecurityTrustResourceUrl('http://sitttrkerala.ac.in/index.php?r=site%2Fdiploma-syllabus-courses&prog=ME');
            this.height = '2630px';
            break;
            case 'electrical and electronics engineering': this.url = this.sanitizer.bypassSecurityTrustResourceUrl('http://sitttrkerala.ac.in/index.php?r=site%2Fdiploma-syllabus-courses&prog=EE');
            break;
            case 'automobile engineering': this.url = this.sanitizer.bypassSecurityTrustResourceUrl('http://sitttrkerala.ac.in/index.php?r=site%2Fdiploma-syllabus-courses&prog=AU');
            break;
            case 'computer engineering': this.url = this.sanitizer.bypassSecurityTrustResourceUrl('http://sitttrkerala.ac.in/index.php?r=site%2Fdiploma-syllabus-courses&prog=CT');
            break;
            case 'electronics engineering': this.url = this.sanitizer.bypassSecurityTrustResourceUrl('http://sitttrkerala.ac.in/index.php?r=site%2Fdiploma-syllabus-courses&prog=EL');
            break;
            default: break;
        }
        let timer: any = setTimeout(() => {
            this.show = true;
        }, 500);
        this.timers.push(timer);
    }
}