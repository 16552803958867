<main-body mainTitle="Contact Us">
    <div class="map-row">
        <div class="map-col">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3935.8130313756005!2d76.340555514206!3d9.437790085052239!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b089b472295c221%3A0x25b9b8650cf8d875!2sCarmel+Polytechnic+College!5e0!3m2!1sen!2sin!4v1448181491966" width="100%" height="250px" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
    <div class="row contact-row">
        <div class="col-6 address-col">
            <sub-body subTitle="Address">
                <box-panel>
                    <table class="contact-table">
                        <tbody>
                            <tr>
                                <td><i class="fa fa-map-marker"></i></td>
                                <td><span class="bold">Carmel Polytechnic College</span>, <br/>
                                    Punnapra, <br/>
                                    Alappuzha - 688 004, <br/>
                                    Kerala, <br/>India.
                                </td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-phone"></i></td>
                                <td>0477 2287825 (Office)</td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-phone"></i></td>
                                <td>0477 2288825 (Self Financing)</td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-phone"></i></td>
                                <td>80783 25976 (Admission Cell)</td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-fax"></i></td>
                                <td>0477 2286048 (Fax)</td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-envelope"></i></td>
                                <td>info@carmelpoly.in</td>
                            </tr>
                            <tr>
                                <td><i class="fa fa-globe"></i></td>
                                <td>www.carmelpoly.in</td>
                            </tr>
                        </tbody>
                    </table>
                </box-panel>
            </sub-body>
        </div>
        <div class="col-6 form-col">
            <sub-body subTitle="Drop us a note">
                <form class="control-form" [formGroup]="form" (ngSubmit)="onSubmit($event)" autocomplete="off" role="presentation">
                    <div class="control-row">
                        <label>Name<span class="mandatory">*</span></label>
                        <input formControlName="name" type="text" maxlength="50" [ngClass]="{'error-border': form.controls.name.errors?.required && isSubmitClicked}"/>
                    </div>
                    <div class="control-row">
                        <label>Email Address<span class="mandatory">*</span></label>
                        <input formControlName="email" type="text" maxlength="50" [ngClass]="{'error-border': form.controls.email.errors?.error && isSubmitClicked}"/>
                    </div>
                    <div class="control-row">
                        <label>Phone<span class="mandatory">*</span></label>
                        <input formControlName="phone" type="text" maxlength="20" [ngClass]="{'error-border': form.controls.phone.errors?.error && isSubmitClicked}"/>
                    </div>
                    <div class="control-row">
                        <label>Subject<span class="mandatory">*</span></label>
                        <input formControlName="subject" type="text" maxlength="100" [ngClass]="{'error-border': form.controls.subject.errors?.required && isSubmitClicked}"/>
                    </div>
                    <div class="control-row">
                        <label>Message<span class="mandatory">*</span></label>
                        <textarea formControlName="message" placeholder="Maximum 300 characters allowed." maxlength="300" [ngClass]="{'error-border': form.controls.message.errors?.required && isSubmitClicked}"></textarea>
                    </div>
                    <div class="control-btn-row">
                        <button type="submit" class="btn-sec btn-sec-purple" [disabled]="!form.valid">
                            Submit
                            <spinner *ngIf="submitting" [type]="'btn-primary'" [color]="'white'" class="fa-right"></spinner>
                            <fa *ngIf="!submitting" name="envelope" class="fa-right"></fa>
                        </button>
                        <button type="reset" class="btn-sec btn-sec-grey">
                            Reset
                            <fa name="refresh" class="fa-right"></fa>
                        </button>
                        <message-box *ngIf="error || success" [maxWidth]="'100%'" [type]="error ? 'Error' : (success ? 'Success' : null)" [message]="error ? error : (success ? success : null)"></message-box>
                    </div>
                </form>
            </sub-body>
        </div>
    </div>
</main-body>