import { Component } from '@angular/core';
import { RoutingService } from '@app/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'quick-links',
    templateUrl: './quick-links.component.html',
    styleUrls: ['./quick-links.component.css']
})
export class QuickLinksComponent {

    public route: string;
    private routerSubs: any;
    
    constructor(
        private router: Router,
        private routingService: RoutingService,
        private activatedRoute: ActivatedRoute) {

        this.routerSubs = this.router.events.subscribe((value: any) => {
            if (value instanceof NavigationEnd) {
                this.activatedRoute.params
                .subscribe((params: any) => {
                    this.route = value.url;
                    this.routingService.setBreadcrumbAndNav(value.url, params);
                });
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.routerSubs) {
          this.routerSubs.unsubscribe();
        }
      }
}