<div class="row panel-header" [ngClass]="{'no-click': hideToggleButton}" (click)="togglePanel($event)">
    <span class="panel-icon" [innerHtml]="icon"></span>
    {{panelTitle}}
    <span *ngIf="!hideToggleButton" class="toggle-icon"><i class="fa" [ngClass]="{'fa-angle-down': panelState === 'close', 'fa-angle-up': panelState === 'open'}"></i></span>
</div>
<div class="row panel-body" [@slideUpDown]="panelState" #body>
    <div class="panel-content" #content>
        <ng-content></ng-content>
    </div>
</div>
