import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '@app/app.config';
import { CoolLocalStorage } from '@angular-cool/storage';
import { getErrorMessage } from '../util';
import { RoutingService } from './routing.service';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private localStorage: CoolLocalStorage;
    private candidateSession: any;
    private cache: any = {};
    private bannerIndex: number = null;
    public userEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private routingService: RoutingService,
        private httpClient: HttpClient,
        localStorage: CoolLocalStorage) {
            this.localStorage = localStorage;
        }

        public get(endPoint: string, isRelativeEndPoint: boolean, params?: any, cacheKey?: string, forced?: boolean, accessToken?: string): Observable<any> {
            if (cacheKey && this.cache[cacheKey]) {
                return this.cache[cacheKey];
            }
            let request: any;
            let header: HttpHeaders = new HttpHeaders();
            header = header.set('Content-Type', 'application/json');
            if (!isRelativeEndPoint) {
                header = header.set('Absolute-Endpoint', 'true');
            }
            if (accessToken) {
                header = header.set('Authorization', accessToken);
            }
            let options: any = {
                headers: header,
                params: params,
                reportProgress: true,
                responseType: 'json',
                withCredentials: false
            };
            request = this.httpClient.get(endPoint, options);
            if (cacheKey) {
                this.cache[cacheKey] = request;
                return this.cache[cacheKey];
            } else if (!cacheKey) {
                return request;
            }
        }

    public post(endPoint: string, isRelativeEndPoint: boolean, body: any, isMultiPart?: boolean): Observable<any> {
        let header: HttpHeaders = new HttpHeaders();
        header = header.set(isMultiPart ? 'Accept' : 'Content-Type', 'application/json');
        if (!isRelativeEndPoint) {
            header = header.set('Absolute-Endpoint', 'true');
        }
        let options: any = {
            headers: header,
            reportProgress: true,
            responseType: 'json',
            withCredentials: false
        };
        return this.httpClient.post(endPoint, body, options);
    }

    public put(endPoint: string, isRelativeEndPoint: boolean, body: any, isMultiPart?: boolean): Observable<any> {
        let header: HttpHeaders = new HttpHeaders();
        header = header.set(isMultiPart ? 'Accept' : 'Content-Type', 'application/json');
        if (!isRelativeEndPoint) {
            header = header.set('Absolute-Endpoint', 'true');
        }
        let options: any = {
            headers: header,
            reportProgress: true,
            responseType: 'json',
            withCredentials: false
        };
        return this.httpClient.put(endPoint, body, options);
    }

    public updateBannerIndex(index?: number): number {
        if ((index || index === 0) && index <= (AppConfig.BANNER_SLIDES.length - 1)) {
            this.bannerIndex = index;
        } else if ((this.bannerIndex || this.bannerIndex === 0) && this.bannerIndex < (AppConfig.BANNER_SLIDES.length - 1)) {
            this.bannerIndex ++;
        } else if (this.bannerIndex && this.bannerIndex === (AppConfig.BANNER_SLIDES.length - 1)) {
            this.bannerIndex = 0;
        } else {
            let rand: number = (Math.floor(Math.random() * 10) + 1);
            this.bannerIndex = rand % (AppConfig.BANNER_SLIDES && AppConfig.BANNER_SLIDES.length ? AppConfig.BANNER_SLIDES.length : 1);
        }
        return this.bannerIndex;
    }

    public setCandidateSession(session: any): void {
        this.candidateSession = session;
        let candidateSession: string = null;
        try {
            candidateSession = JSON.stringify(this.candidateSession);
        } catch (err) {
            getErrorMessage(err, 'DataService');
        }
        this.localStorage.setObject('candidate', candidateSession);
        this.routingService.emitUser();
    }

    public getCandidateSession(): any {
        if (this.candidateSession) {
            return this.candidateSession;
        }
        try {
            this.candidateSession = JSON.parse(this.localStorage.getObject('candidate'));
        } catch (err) {
            getErrorMessage(err, 'DataService');
        }
        return this.candidateSession;
    }

    public clearCandidateSession(): void {
        this.candidateSession = null;
        this.localStorage.removeItem('candidate');
        this.routingService.emitUser();
    }
}
