import { Routes } from '@angular/router';

import { AdmissionsComponent } from '@app/admissions-module/admissions/admissions.component';

export const AdmissionsRoutes: Routes = [
  {
    path: '',
    component: AdmissionsComponent,
    data: {
      title: 'Admissions'
    }
  }
];
