import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'myAdmissionYearPipe'
})
export class AdmissionYearPipe implements PipeTransform {
    public transform(dateStr: string): string {
        if (!dateStr) {
            return moment().format('YYYY') + '-' + moment().add(1, 'year').format('YYYY');
        }
        try {
            let date: moment.Moment = moment(dateStr);
            return date.format('YYYY') + '-' + date.clone().add(1, 'year').format('YYYY');
        } catch (e) {
            console.error('Exception in parsing created date for admission year: ', e);
            return '';
        }
    }
}
