import { Component, Input } from '@angular/core';

@Component({
    selector: 'departments',
    templateUrl: './departments.component.html',
    styleUrls: ['./departments.component.css']
})

export class DepartmentsComponent {

    @Input() singleCol: boolean = false;
    @Input() hasTitle: boolean = true;
 }