import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Message, isNotNullAndNotEmpty } from '@app/core';

@Component({
    selector: 'faculty',
    templateUrl: './faculty.component.html',
    styleUrls: ['./faculty.component.css']
})
export class FacultyComponent implements OnChanges {

    @Input() public error: Message;
    @Input() public staffs: any[];
    @Input() public tabTitle: string;
    @Input() public department: string;
    public hod: any;
    public spdt: any;

    public ngOnChanges(changes: SimpleChanges): void {
        if ('staffs' in changes) {
            this.hod = isNotNullAndNotEmpty(this.staffs) ? this.staffs.find((s: any) => s.designation === 'Head of the Department') : null;
            this.spdt = isNotNullAndNotEmpty(this.staffs) ? this.staffs.find((s: any) => s.designation.includes('Superintendent')) : null;
        }
        console.log(this.department, this.spdt);
    }
}