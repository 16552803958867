import { FormControl, FormGroup } from '@angular/forms';
import { AppConfig } from '@app/app.config';
import { Application } from '../model/Application';
import { getQualifyingExam } from '.';

export function isMandatoryEmail(control: FormControl): any {
    if (control.value != null && typeof (control.value) !== 'undefined') {
        if (control.value.toString().match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            return null;
        } else if (control.value.toString().trim().length === 0) {
            return {
                error: 'Email Id. is required.'
            };
        } else {
            return {
                error: 'Enter a valid Email Id.'
            };
        }
    } else {
        return {
            error: 'Email Id. is required.'
        };
    }
}

export function isValidEmail(control: FormControl): any {
    if (control.value != null && typeof (control.value) !== 'undefined' && control.value.toString().length > 0) {
        if (control.value.toString().match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            return null;
        } else {
            return {
                error: 'Enter a valid Email Id.'
            };
        }
    }
    return null;
}

export function isMandatoryPassword(control: FormControl): any {
    if (control.value != null && typeof (control.value) !== 'undefined') {
        if (control.value.toString().length >= 8) {
            return null;
        } else if (control.value.toString().trim().length === 0) {
            return {
                error: 'Password is required.'
            };
        } else {
            return {
                error: 'Password must contain at least 8 characters.'
            };
        }
    } else {
        return {
            error: 'Password is required.'
        };
    }
}

export function isValidPassword(control: FormControl): any {
    if (control.value != null && typeof (control.value) !== 'undefined' && control.value.toString().length > 0) {
        if (control.value.toString().length >= 8) {
            return null;
        } else {
            return {
                error: 'Password must contain at least 8 characters.'
            };
        }
    }
    return null;
}

export function isMandatoryPhoneNumber(control: FormControl): any {
    if (control.value != null && typeof (control.value) !== 'undefined') {
        if (control.value.match(/^[0-9]{1,10}$/)) {
            return null;
        } else if (control.value.toString().trim().length === 0) {
            return { error: 'Phone number is required.' };
        } else {
            return { error: 'Enter a valid phone number.' };
        }
    }
}

export function isValidDocument(control: FormControl): any {
    if (control.value && control.value.error) {
        return {
            error: control.value.error.message
        };
    }
    return null;
}

export function isMandatoryUsername(usernameStatus: boolean): any {
    return (control: FormControl) => {
        if (control.value && usernameStatus !== true && control.value.trim().length > 0) {
            return null;
        } else if (control.value && usernameStatus === true && control.value.trim().length > 0) {
            return { error: 'Username already exists, choose another one' };
        } else {
            return { error: 'This field is required' };
        }
    };
}

export function isNotNullAndNotEmpty(array: any[]): boolean {
    if (array && array !== null && typeof (array) !== 'undefined' && array.length > 0) {
        return true;
    } else {
        return false;
    }
}

export function isNullOrEmpty(array: any[]): boolean {
    if (!array || array === null || typeof (array) === 'undefined' || array.length < 1) {
        return true;
    } else {
        return false;
    }
}

export function isNumberKey(event: any): boolean {
    let charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

export function isSuperAdminUser(user: any): boolean {
    return user && AppConfig.EMPLOYEE_ROLE_SUPER_ADMIN === user.role;
}

export function isAuthorizedCustomerAdmin(companyId: number, user: any): boolean {
    return user && AppConfig.EMPLOYEE_ROLE_ADMIN === user.role && companyId === user.company.id;
}

export function isAdminUser(user: any): boolean {
    return user && AppConfig.EMPLOYEE_ROLE_ADMIN === user.role;
}

export function isAuthorizedCustomerUser(companyId: number, user: any): boolean {
    return user && companyId === user.company.id;
}

export function isValidCompany(companyKey: string, user: any): any {
    return (group: FormGroup) => {
        let company: any = group.controls[companyKey];
        company.setErrors(null);
        if (!company.value && user.role === 'Super Admin') {
            company.setErrors({error: 'This field is required.'});
        }
    };
}

export function isValidEmployeeForm(companyKey: string, branchKey: string, statusKey: string, roleKey: string, user: any): any {
    return (group: FormGroup) => {
        let company: any = group.controls[companyKey];
        let branch: any = group.controls[branchKey];
        let status: any = group.controls[statusKey];
        let role: any = group.controls[roleKey];
        company.setErrors(null);
        branch.setErrors(null);
        status.setErrors(null);
        role.setErrors(null);
        if (user.role === 'Super Admin') {
            if (!company.value) {
                company.setErrors({error: 'This field is required.'});
            }
            if (!branch.value) {
                branch.setErrors({error: 'This field is required.'});
            }
            if (!status.value) {
                status.setErrors({error: 'This field is required.'});
            }
            if (!role.value) {
                role.setErrors({error: 'This field is required.'});
            }
        } else if (user.role === 'Admin') {
            if (!branch.value) {
                branch.setErrors({error: 'This field is required.'});
            }
            if (!status.value) {
                status.setErrors({error: 'This field is required.'});
            }
            if (!role.value) {
                role.setErrors({error: 'This field is required.'});
            }
        }
    };
}

export function isValidChitGroupForm(companyKey: string, branchKey: string, statusKey: string, dateOfRegistrationKey: string, registrationNumberKey: string, dateOfCommencementKey: string, user: any, creating: boolean, editing: boolean): any {
    return (group: FormGroup) => {
        let company: any = group.controls[companyKey];
        let branch: any = group.controls[branchKey];
        let status: any = group.controls[statusKey];
        let dateOfRegistration: any = group.controls[dateOfRegistrationKey];
        let registrationNumber: any = group.controls[registrationNumberKey];
        let dateOfCommencement: any = group.controls[dateOfCommencementKey];
        company.setErrors(null);
        branch.setErrors(null);
        status.setErrors(null);
        dateOfRegistration.setErrors(null);
        registrationNumber.setErrors(null);
        dateOfCommencement.setErrors(null);
        if (user.role === 'Super Admin') {
            if (!company.value) {
                company.setErrors({error: 'This field is required.'});
            }
            if (!branch.value) {
                branch.setErrors({error: 'This field is required.'});
            }
        } else if (user.role === 'Admin') {
            if (!branch.value) {
                branch.setErrors({error: 'This field is required.'});
            }
        }
        if (!creating && !status.value) {
            status.setErrors({error: 'This field is required.'});
        }
        if ((creating || editing) && (status.value === 'Registered' || status.value === 'In Production' || status.value === 'Completed' || status.value === 'Paused')) {
            if (!registrationNumber.value) {
                registrationNumber.setErrors({error: 'This field is required for ' + status.value + ' status.'});
            }
            if (!dateOfRegistration.value) {
                dateOfRegistration.setErrors({error: 'This field is required for ' + status.value + ' status.'});
            }
        }
        if ((creating || editing) && (status.value === 'In Production' || status.value === 'Completed' || status.value === 'Paused')) {
            if (!dateOfCommencement.value) {
                dateOfCommencement.setErrors({error: 'This field is required for ' + status.value + ' status.'});
            }
        }
    };
}

export function isValidPaymentForm(paymentModeKey: string, installmentsPayableKey: string): any {
    return (group: FormGroup) => {
        let paymentMode: any = group.controls[paymentModeKey];
        let installmentsPayable: any = group.controls[installmentsPayableKey];
        paymentMode.setErrors(null);
        installmentsPayable.setErrors(null);
        if (!paymentMode.value || 
            (paymentMode.value.mode === 'Cheque' && (!paymentMode.value.bankOrAppName || paymentMode.value.bankOrAppName.trim().length === 0 || !paymentMode.value.chequeNumber || paymentMode.value.chequeNumber.trim().length === 0)) || 
            (paymentMode.value.mode === 'Transfer' && (!paymentMode.value.bankOrAppName || paymentMode.value.bankOrAppName.trim().length === 0 || !paymentMode.value.transactionId || paymentMode.value.transactionId.trim().length === 0))) {
            paymentMode.setErrors({error: 'This field is required.'});
        }
        if (isNullOrEmpty(installmentsPayable.value)) {
            installmentsPayable.setErrors({error: 'This field is required.'});
        }
    };
}

export function isValidAuctionForm(paymentModeKey: string, dateOfPaymentKey: string, statusKey: string): any {
    return (group: FormGroup) => {
        let paymentMode: any = group.controls[paymentModeKey];
        let dateOfPayment: any = group.controls[dateOfPaymentKey];
        let status: any = group.controls[statusKey];
        paymentMode.setErrors(null);
        dateOfPayment.setErrors(null);
        if (paymentMode.value && 
            ((paymentMode.value.mode === 'Cheque' && (!paymentMode.value.bankOrAppName || paymentMode.value.bankOrAppName.trim().length === 0 || !paymentMode.value.chequeNumber || paymentMode.value.chequeNumber.trim().length === 0)) || 
            (paymentMode.value.mode === 'Transfer' && (!paymentMode.value.bankOrAppName || paymentMode.value.bankOrAppName.trim().length === 0 || !paymentMode.value.transactionId || paymentMode.value.transactionId.trim().length === 0)))) {
            paymentMode.setErrors({error: 'This field is required.'});
        }
        if (!paymentMode.value && status.value && status.value === 'Paid') {
            paymentMode.setErrors({error: 'This field is required.'});
        }
        if (!dateOfPayment.value && status.value && status.value === 'Paid') {
            dateOfPayment.setErrors({error: 'This field is required.'});
        }
        console.log('Mode', paymentMode.value)
    };
}

export function isValidPasswords(newPasswordKey: string, cnfPasswordKey: string): any {
    return (group: FormGroup) => {
        let newPassword: any = group.controls[newPasswordKey];
        let cnfPassword: any = group.controls[cnfPasswordKey];
        newPassword.setErrors(null);
        cnfPassword.setErrors(null);
        let error: boolean = false;
        if (!newPassword.value) {
            newPassword.setErrors({error: 'This field is required'});
            error = true;
        } else if (newPassword.value.toString().length < 8) {
            newPassword.setErrors({error: 'Password must be min. 8 characters'});
            error = true;
        }
        if (!cnfPassword.value) {
            cnfPassword.setErrors({error: 'This field is required'});
            error = true;
        } else if (cnfPassword.value.toString().length < 8) {
            cnfPassword.setErrors({error: 'Password must be min. 8 characters'});
            error = true;
        }
        if (!error && cnfPassword.value && newPassword.value && newPassword.value !== cnfPassword.value) {
            cnfPassword.setErrors({error: 'Password is not matching'});
        }
    };
}

export function isValidApplicationNumber(control: FormControl): any {
    if (!control || !control.value || control.value === null || typeof(control.value) === 'undefined' || control.value.trim().length === 0) {
        return {
            error: 'This field is required'
        };
    }

    let appNumArr: string[] = control.value.toString().split('-');
    if (appNumArr.length !== 3 || appNumArr[0] !== 'CRML' ||
        appNumArr[1].length < 5 || !appNumArr[1].match(/^\d+$/) || appNumArr[2].length !== 8 || !appNumArr[2].match(/^\d+$/)) {
        return {
            error: 'Invalid application number'
        };
    }
}

export function isValidSignIn(userType: string, userIdKey: string, passwordkey: string): any {
    return (group: FormGroup) => {
        let userId: any = group.controls[userIdKey];
        let password: any = group.controls[passwordkey];
        if (!userId || !userId.value || userId.value.trim().length === 0) {
            return userId.setErrors({error: 'This field is required'});
        }
        if (userType && userType === 'Candidate') {
            let appNumArr: string[] = userId.value.toString().split('-');
            if (appNumArr.length !== 3 || appNumArr[0] !== 'CRML' ||
                appNumArr[1].length < 5 || !appNumArr[1].match(/^\d+$/) || appNumArr[2].length !== 8 || !appNumArr[2].match(/^\d+$/)) {
                return userId.setErrors({error: 'Invalid application number'});
            }
            if (!password || !password.value) {
                return password.setErrors({error: 'Invalid password'});
            }
            if (password.value.toString().trim().length < 8) {
                return password.setErrors({error: 'Password must be minimum 8 characters'});
            }
        }
    };
}

export function isValidBranchPreference(preference: any): boolean {
    if (preference && ((preference.meritChoiceOne && !preference.quotaChoiceOne)
        || (preference.quotaChoiceOne && isContainsAided(preference) && preference.govtApplnNo && preference.govtApplnNo.length === 6 && !preference.isDuplicateGovtApplnNo) || (preference.quotaChoiceOne && !isContainsAided(preference)))) {
        return true;
    }
    return false;
}

export function isContainsAided(preference: any): boolean {
    let aided: string[] = ['Civil', 'Mechanical', 'Electrical and Electronics'];
    if (preference.quotaChoiceOne && aided.indexOf(preference.quotaChoiceOne.name) > -1) {
        return true;
    }
    if (preference.quotaChoiceTwo && aided.indexOf(preference.quotaChoiceTwo.name) > -1) {
        return true;
    }
    if (preference.quotaChoiceThree && aided.indexOf(preference.quotaChoiceThree.name) > -1) {
        return true;
    }
    if (preference.quotaChoiceFour && aided.indexOf(preference.quotaChoiceFour.name) > -1) {
        return true;
    }
    if (preference.quotaChoiceFive && aided.indexOf(preference.quotaChoiceFive.name) > -1) {
        return true;
    }
    if (preference.quotaChoiceSix && aided.indexOf(preference.quotaChoiceSix.name) > -1) {
        return true;
    }
    return false;
}

export function isValidAcademics(academics: any): boolean {
    if (!academics) {
        return false;
    }
    let qualifyingExam: any = null;
    if (isNotNullAndNotEmpty(academics.academicHistory)) {
        qualifyingExam = academics.academicHistory.find((history: any) => history && history.levelExam && history.levelExam.level.name === 'Xth');
    }
    let examName: string = qualifyingExam ? qualifyingExam.levelExam.exam.name : null;
    if (!isValidAcademicHistory(academics.academicHistory) || !academics.chances ||
    !isValidExamMarks(academics.examMarks, examName, academics.grade ? 'Grade' : 'Marks') || !academics.qualifyingExamName) {
        return false;
    }
    return true;
}

export function isValidAcademicHistory(academicHistories: any[]): boolean {
    let status = true;
    if (isNullOrEmpty(academicHistories)) {
        return false;
    }
    academicHistories.forEach((history) => {
        if (!history.levelExam || !history.university || !history.institution || !history.dateOfPassing) {
            status = false;
        }
    });
    return status;
}

export function isValidExamMarks(examMarks: any[], qualifyingExam: string, gradeOrMarks: string): boolean {
    let status = true;
    if (isNullOrEmpty(examMarks)) {
        return false;
    }
    examMarks.forEach((examMark) => {
        if (!qualifyingExam || (qualifyingExam === 'Other Xth Equivalent' && !examMark.subjectName) || (qualifyingExam !== 'Other Xth Equivalent' && !examMark.subject) ||
        !gradeOrMarks || (gradeOrMarks === 'Grade' && !examMark.grade) || (gradeOrMarks === 'Marks' && !examMark.marksScored) ||
        (qualifyingExam === 'Other Xth Equivalent' && gradeOrMarks === 'Marks' && (!examMark.marksScored || !examMark.totalMarks))) {
            status = false;
        }
    });
    return status;
}

export function isValidProfileForSave(form: Application): boolean {
    if (form && form.candidateProfile && form.candidateProfile.fullName && form.candidateProfile.emailId && !form.candidateProfile.isDuplicateEmail && !form.candidateProfile.isDuplicateAadharNo) {
        if (form.candidateProfile.emailId.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            return true;
        }
        return false;
    }
    return false;
}

export function isValidPreferenceForSave(form: Application): boolean {
    if (form && (!form.branchPreference || !form.branchPreference.govtApplnNo)) {
        return true;
    }
    if (form && form.branchPreference && form.branchPreference.govtApplnNo && isValidGovtApplnNo(form.branchPreference.govtApplnNo) && !form.branchPreference.isDuplicateGovtApplnNo) {
        return true;
    }
    return false;
}

export function isValidGovtApplnNo(applnNo: Number): boolean {
    if (applnNo.toString().length === 6) {
        return true;
    }
    return false;
}

export function isValidForSave(form: Application): boolean {
    if (isValidProfileForSave(form) && isValidPreferenceForSave(form)) {
        return true;
    }
    return false;
}

export function isValidForApply(form: Application): boolean {
    if (form && form.validFields && form.validFields.candidateProfile && form.validFields.branchPreference && form.validFields.academics) {
        return true;
    }
    return false;
}

export function isValidCommunity(religionKey: string, christianKey: string, othersKey: string): any {
    return (group: FormGroup) => {
        let religion: any = group.controls[religionKey];
        let community: any = group.controls[christianKey];
        let communityOther: any = group.controls[othersKey];
        if (!community.value && !communityOther.value) {
            if (religion.value && religion.value.name === 'Christian') {
                return community.setErrors({error: 'This field is required'});
            } else if (religion.value) {
                return communityOther.setErrors({error: 'This field is required'});
            } else {
                return community.setErrors({error: 'This field is required'});
            }
        }
        return community.setErrors(null);
    };
}

export function isDuplicateEmail(control: FormControl): any {
    if (control.value === true) {
        return {
            error: 'Email address already exists. Please contact admin.'
        };
    }
    return null;
}

export function isDuplicateAadharNo(control: FormControl): any {
    if (control.value === true) {
        return {
            error: 'Aadhar no. already exists. Please contact admin.'
        };
    }
    return null;
}

export function isMandatoryWholeNumber(control: FormControl): any {
    if (control.value !== null && typeof(control.value) !== 'undefined') {
        if (control.value.toString().match(/^\d+$/)) {
            return null;
        } else if (control.value.toString().trim().length === 0) {
            return {
                error: 'This field is required to apply.'
            };
        } else {
            return {
                error: 'Enter a valid whole number to apply.'
            };
        }
    }
    return {
        error: 'This field is required to apply.'
    };
}

export function isValidPreference(meritChoiceOneKey: string, quotaChoiceOneKey: string, quotaChoiceTwoKey: string, quotaChoiceThreeKey: string, quotaChoiceFourKey: string, quotaChoiceFiveKey: string, quotaChoiceSixKey: string, numberKey: string, isDuplicateKey: string): any {
    return (group: FormGroup) => {
        let meritChoiceOne: any = group.controls[meritChoiceOneKey];
        let quotaChoiceOne: any = group.controls[quotaChoiceOneKey];
        let quotaChoiceTwo: any = group.controls[quotaChoiceTwoKey];
        let quotaChoiceThree: any = group.controls[quotaChoiceThreeKey];
        let quotaChoiceFour: any = group.controls[quotaChoiceFourKey];
        let quotaChoiceFive: any = group.controls[quotaChoiceFiveKey];
        let quotaChoiceSix: any = group.controls[quotaChoiceSixKey];
        let preference: any = {
            quotaChoiceOne: quotaChoiceOne ? quotaChoiceOne.value : null,
            quotaChoiceTwo: quotaChoiceTwo ? quotaChoiceTwo.value : null,
            quotaChoiceThree: quotaChoiceThree ? quotaChoiceThree.value : null,
            quotaChoiceFour: quotaChoiceFour ? quotaChoiceFour.value : null,
            quotaChoiceFive: quotaChoiceFive ? quotaChoiceFive.value : null,
            quotaChoiceSix: quotaChoiceSix ? quotaChoiceSix.value : null
        }
        let applnNumber: any = group.controls[numberKey];
        if (!meritChoiceOne.value && !quotaChoiceOne.value) {
            meritChoiceOne.setErrors({error: 'This field is required to apply.'});
            quotaChoiceOne.setErrors({error: 'This field is required to apply.'});
            return;
        }
        if (quotaChoiceOne.value && isContainsAided(preference) && (!applnNumber.value || applnNumber.value.trim().length === 0)) {
            return applnNumber.setErrors({error: 'This field is required.'});
        }
        if (applnNumber.value && applnNumber.value.length !== 6) {
            return applnNumber.setErrors({error: 'Must be a six digit number.'});
        }

        let isDuplicate: any = group.controls[isDuplicateKey];
        if (applnNumber.value && isDuplicate.value) {
            return isDuplicate.setErrors({error: 'Application Number (www.polyadmission.org) already exists. Please contact admin.'});
        }

        meritChoiceOne.setErrors(null);
        applnNumber.setErrors(null);
        isDuplicate.setErrors(null);
        return;
    };
}