import { isNotNullAndNotEmpty } from './validations';
import { Message, Application } from '..';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';

export function getParamString(params: any): string {
    if (params) {
        let str: string = ``;
        for (let key in params) {
            if (params.hasOwnProperty(key) && params[key] !== null && params[key] !== undefined) {
                str += str.length > 0 ? `&${key}=${params[key]}` : `${key}=${params[key]}`;
            }
        }
        return str ? `?${str}` : ``;
    }
    return ``;
}

export function containsKey(object: any, key: string): boolean {
    if (object) {
        if (object.hasOwnProperty(key)) {
            return true;
        }
    }
    return false;
}

export function getErrorMessage(error: any, source: string, isResponsive?: boolean): string {
    try {
        if (error && error.error && error.error.map && error.error.map.MESSAGE && error.error.map.MESSAGE.trim().length > 0) {
            let message: string = error.error.map.MESSAGE.trim();
            console.error(`Exception @ ${source}. `, message);
            return message;
        }
        if (error && error.error && error.error.status === 403 && error.error.message === 'Invalid Token') {
            let message: string = 'Oops, you have timed out. Please login again.';
            let shortMessage: string = 'You have timed out.';
            console.error(`Exception @ ${source}. `, message);
            return !isResponsive ? message : shortMessage; 
        }
        console.error(`Exception @ ${source}.`);
        return !isResponsive ? 'Oops, unable to serve your request.' : 'Unable to serve your request.';
    } catch (e) {
        console.error(`Exception @ ${source}. `, e);
        return !isResponsive ? 'Oops, unable to serve your request.' : 'Unable to serve your request.';
    }
}

export function getError(error: any, source: string): Message {
    let message: Message = {
        message: getErrorMessage(error, source),
        shortMessage: getErrorMessage(error, source, true)
    };
    return message;
}

export function clone(input: any): any {
    if (input) {
        return JSON.parse(JSON.stringify(input));
    }
    return null;
}

export function clearIntervals(timers: number[]): void {
    if (isNotNullAndNotEmpty(timers)) {
        timers.forEach((timer) => {
            clearInterval(timer);
        });
    }
}

export function clearTimers(timers: number[]): void {
    if (isNotNullAndNotEmpty(timers)) {
        timers.forEach((timer) => {
            clearTimeout(timer);
        });
    }
}

export function clearTimer(timer: number): void {
    if (timer) {
        clearTimeout(timer);
    }
}

export function mapObjectArray(objectArray: any[], key: string): any[] {
    if (isNotNullAndNotEmpty(objectArray)) {
      return objectArray.map((o: any) => o[key]);
    }
    return [];
}

export function getAnotherKey(objectArray: any[], availableKey: string, availableValue: string, returnKey: string): any {
    if (isNotNullAndNotEmpty(objectArray) && availableKey && availableValue && returnKey) {
      let object: any = objectArray.find((o: any) => o[availableKey] === availableValue);
      if (object) {
        return object[returnKey];
      }
    }
    return '';
}

export function getTotal(objectArray: any[], key: string): number {
    let sum: number = 0;
    if (isNotNullAndNotEmpty(objectArray) && key) {
        objectArray.forEach((o: any) => {
            sum += (o[key] ? o[key] : 0);
        });
    }
    return sum;
}

export function mapUserInfo(user: any): any {
    if (user) {
      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.employeeRole.name,
        accessToken: user.accessToken,
        avatar: user.avatar,
        company: {
            id: user.branch.company.id,
            name: user.branch.company.name
        },
        branch: {
            id: user.branch.id,
            name: user.branch.name
        }
      };
    }
    return null;
}

export function convertTzOfDateStr(dateStr: string, srcTz: string, destTz: string): moment.Moment {
    return moment(momentTz.tz(dateStr, srcTz).tz(destTz).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
}

export function getFullName(firstName: string, lastName: string): string {
    if (!lastName) {
        return firstName ? firstName : null;
    }
    if (!firstName) {
        return lastName ? lastName : null;
    }
    return firstName + ' ' + lastName;
}

export function getCsvString(list: any[]): string {
    if (isNotNullAndNotEmpty(list)) {
        let str: string = '';
        list.forEach((e: any) => {
            if (str.length > 0) {
                str += ', '
            }
            str += e;
        });
        return str;
    }
    return null;
}

export function getMonthDropdown(startMoment: moment.Moment, endMoment: moment.Moment, isMonthOnly?: boolean, tolerance?: number): any[] {
    let monthArray: any = [];
    let outputFormat: string = 'MMM YYYY';
    if (startMoment.isSame(endMoment) || startMoment.isBefore(endMoment)) {
        startMoment = !tolerance || tolerance === 0 ? startMoment.startOf('month') : startMoment.subtract(tolerance, 'months').startOf('month');
        endMoment = !tolerance || tolerance === 0 ? endMoment.startOf('month') : endMoment.add(tolerance, 'months').startOf('month');
    } else {
        startMoment = !tolerance || tolerance === 0 ? startMoment.startOf('month') : startMoment.add(tolerance, 'months').startOf('month');
        endMoment = !tolerance || tolerance === 0 ? endMoment.startOf('month') : endMoment.subtract(tolerance, 'months').startOf('month');
    }

    if (isMonthOnly) {
        outputFormat = 'MMMM';
    }
    if (!startMoment.isSame(endMoment)) {
        do {
            monthArray.push({name: startMoment.format(outputFormat), value: startMoment.format('YYYY-MM-DD')});
            if (startMoment.isAfter(endMoment)) {
                startMoment.subtract(1, 'month');
            } else {
                startMoment.add(1, 'month');
            }
        } while (!startMoment.isSame(endMoment));
    }
    monthArray.push({name: endMoment.format(outputFormat), value: endMoment.format('YYYY-MM-DD')});
    return monthArray;
}

export function getWindowHeight(): number {
    return window.innerHeight;
}

export function getWindowWidth(): number {
    return window.innerWidth;
}

export function getDepartment(urlParam: string): string {
    if (urlParam === 'civil-engineering') {
        return 'Civil Engineering';
    } else if (urlParam === 'mechanical-engineering') {
        return 'Mechanical Engineering';
    } else if (urlParam === 'electrical-and-electronics-engineering') {
        return 'Electrical and Electronics Engineering';
    } else if (urlParam === 'automobile-engineering') {
        return 'Automobile Engineering';
    } else if (urlParam === 'computer-engineering') {
        return 'Computer Engineering';
    } else if (urlParam === 'electronics-engineering') {
        return 'Electronics Engineering';
    } else if (urlParam === 'general-engineering') {
        return 'General Engineering';
    } else if (urlParam === 'workshop') {
        return 'Workshop';
    } else {
        return 'Office Administration';
    }
}

export function getSubjectKey(subject: string): string {
    if (!subject) {
        return null;
    }
    return subject.toLowerCase().split(' ').join('_');
}

export function mapResponseToForm(response: any): Application {
    let dateOfBirthStr: string = response.candidateProfile.dateOfBirth;
    if (dateOfBirthStr) {
        response.candidateProfile.dateOfBirth = moment(dateOfBirthStr);
    }
    let academicHistory: any[] = response.academics ? response.academics.academicHistory : [];
    if (isNotNullAndNotEmpty(academicHistory)) {
        academicHistory.forEach((history) => {
            history.dateOfPassing = history.dateOfPassing ? moment(history.dateOfPassing) : null;
        });
        response.academics.academicHistory = academicHistory;
    }
    response.applied = response.appliedOn ? true : false;
    return response;
}

export function suffixCharacter(str: string, char: string): string {
    if (str && char) {
        if (str.endsWith(char)) {
            return str;
        }
        return str + char;
    }
    return str;
}

export function getNumberDropdowns(minValue: number, maxValue: number, stepValue?: number): any[] {
    let result: any[] = [];
    minValue = minValue ? minValue : 0;
    stepValue = stepValue ? stepValue : 1;
    if (maxValue !== null && maxValue !== undefined) {
        let count = 1;
        while (minValue <= maxValue) {
            result.push({id: count, name: minValue});
            count ++;
            minValue += stepValue;
        }
    }
    return result;
}

export function getQualifyingExam(academicHistory: any[]): any {
    if (isNotNullAndNotEmpty(academicHistory)) {
        return academicHistory.find((history) => history && history.levelExam && history.levelExam.level.name === 'Xth');
    }
    return null;
}

export function setAcademicsFormData(form: any): any {
    form.chances = form.chances ? +form.chances.name : null;
    form.grade = form.gradeOrMarks ? (form.gradeOrMarks.name === 'Grade' ? true : false) : null;
    return form;
}