<div class="banner" #banner>
    <banner-text [index]="index"></banner-text>
    <button *ngIf="bannerSlides && bannerSlides.length > 0" class="slide-navigator">
        <a *ngFor="let bannerSlide of bannerSlides; let i = index" [title]="'Slide ' + (i + 1)" [ngClass]="{'active-slide': i === slideIndex}" (click)="showThis(i)"></a>
    </button>
    <div class="slide-wrap" [ngStyle]="{'background-image': (slide ? ('url(' + slide + '?v=3.0)') : 'none')}" #mainSlide></div>
    <div class="slide-next-wrap" [ngStyle]="{'background-image': (slideNext ? ('url(' + slideNext + '?v=3.0)') : 'none')}"></div>
</div>
<div class="slider-preloader">
    <img *ngFor="let bannerSlide of bannerSlides" [src]="bannerSlide" alt/>
</div>