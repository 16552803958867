export class AppConfig {
    public static APP_GRID_ROWS: number = 20;
    public static UTC_TIMEZONE: string = 'UTC';
    public static APP_TIMEZONE: string = 'Asia/Kolkata';
    public static SERVER_TIMEZONE: string = 'Asia/Kolkata';
    public static EMPLOYEE_ROLE_SUPER_ADMIN: string = 'Super Admin';
    public static EMPLOYEE_ROLE_ADMIN: string = 'Admin';
    public static EMPLOYEE_ROLE_STAFF: string = 'Staff';
    public static CHIT_GROUP_STATUS_NEW: string = 'New'
    public static APP_START_YEAR: number = 2020;
    public static BANNER_SLIDES: string[] = [
        '/assets/images/slide_001.png',
        '/assets/images/slide_002.png',
        '/assets/images/slide_003.png',
        '/assets/images/slide_004.png',
        '/assets/images/slide_005.png',
        '/assets/images/slide_006.png',
        '/assets/images/slide_007.png',
        '/assets/images/slide_008.png',
        '/assets/images/slide_009.png'
    ];
};
