/*! *********************************************
 Author: Libu T.Babu, email: crazylibu@yahoo.com
*********************************************** */
import {
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    OnDestroy
} from '@angular/core';
import { TopTabComponent } from './top-tab.component';
import { clearTimers, clearIntervals } from '@app/core';

@Component({
    selector: 'top-tabs',
    templateUrl: './top-tabs.component.html',
    styleUrls: ['./top-tabs.component.css']
})
export class TopTabsComponent implements OnChanges, OnDestroy {
  @ContentChildren(TopTabComponent) public topTabs: QueryList<TopTabComponent>;
  @Output() public activeTabEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() public activateTab: string;
  public border: boolean;
  private timeOuts: number[] = [];
  private timers: number[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if ('activateTab' in changes && this.activateTab) {
      this.setTabs();
    }
  }

  public ngOnInit(): void {
    this.recursivelySetTabs();
  }

  public ngOnDestroy(): void {
    clearTimers(this.timeOuts);
    clearIntervals(this.timers);
  }

  public setTabs(): void {
    let activeTab: TopTabComponent = this.topTabs.find((tab) => tab.active);
    this.topTabs.toArray().forEach((tab) => tab.active = false);
    if (this.activateTab) {
      this.topTabs.toArray().forEach((tab) => {
        if (tab.tabTitle === this.activateTab && tab.enabled) {
          tab.active = true;
          tab.action = 'default';
          let timer: number = setTimeout(() => {
            this.activeTabEmitter.emit(tab);
          });
          this.timeOuts.push(timer);
        }
      });
    } else if (!activeTab && this.topTabs.first.enabled) {
      this.topTabs.first.active = true;
      this.topTabs.first.action = 'default';
      let timer: number = setTimeout(() => {
        this.activeTabEmitter.emit(this.topTabs.first);
      });
      this.timeOuts.push(timer);
    }
  }

  public selectTab(topTab: TopTabComponent): void {
    if (topTab.enabled) {
      this.topTabs.toArray().forEach((tab) => tab.active = false);
      topTab.active = true;
      topTab.action = 'clicked';
      this.activeTabEmitter.emit(topTab);
    }
  }

  private recursivelySetTabs(): void {
    let timer: any = setInterval(() => {
      this.timers.push(timer);
      if (this.topTabs && this.topTabs.length > 0) {
        clearIntervals(this.timers);
        this.setTabs();
      }
    }, 50);
  }
}
