<main-body mainTitle="Alumni">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_alumni" type="pre_banner_sub"></preload-picture>
    </div>
    <div class="row alumni-row">
        <div class="col-8 events-col">
            <div class="row">
                <div class="col-12 col-sm-6 events">
                    <div>
                        <div class="sub-body-title" title="Hostel">
                            Hostel
                            <div class="date"><fa name="calendar"></fa> 25/20/2020, Sunday</div>
                        </div>
                        <image-thumbnail [transform]="true" width="100%" folder="/assets/images" fileName="hostel" type="pre_facility" mainTitle="Hostel"></image-thumbnail>
                        The Polytechnic has two separate hostels, one for the boys and the other for the girls. Warden of the boys' hostel 
                        is Fr. Thomas Pockavaraythu CMI. The Boys' Hostel accommodates 
                        200 students. Matron of the Ladies' Hostel is Mrs. Neethu Fernandez.
                    </div>
                </div>
                <div class="col-12 col-sm-6 events">
                    <div>
                        <div class="sub-body-title">
                            Hostel
                            <div class="date"><fa name="calendar"></fa> 25/20/2020, Sunday</div>
                        </div>
                        <image-thumbnail [transform]="true" width="100%" folder="/assets/images" fileName="hostel" type="pre_facility" mainTitle="Hostel"></image-thumbnail>
                        The Polytechnic has two separate hostels, one for the boys and the other for the girls. Warden of the boys' hostel 
                        is Fr. Siji Thomas P.T. CMI. The Boys' Hostel accommodates 
                        200 students. Matron of the Ladies' Hostel is Sr. Elsa.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4 donation-col">

        </div>
    </div>
</main-body>