<main-body mainTitle="Grievance Redressal Cell" *ngIf="route === '/grievance-redressal-cell'">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_grievance_redressal_cell" type="pre_banner_sub"></preload-picture>
    </div>
    The function of the cell is to look into the complaints lodged by any student, and judge its merit. 
    The Grievance cell is also empowered to look into matters of harassment. Anyone with a genuine 
    grievance may approach the department members in person, or in consultation with the Students 
    Council. In case the person is unwilling to appear in self, grievances may be dropped in writing 
    at the letterbox / suggestion box of the Grievance Cell. They may also register their complaints 
    in the online Grievance Redressal Portal available in the following link.<br/><br/>

    <a href="http://www.carmelpolytechnic.edugrievance.com" target="_blank">www.carmelpolytechnic.edugrievance.com</a><br/><br/>

    An aggrieved student who has the Grievance or Grievances at the Department level shall make an 
    application first to the teacher in charge. The Teacher in charge after verifying the facts will 
    try to redress the grievance within a reasonable time. If the student is not satisfied with the 
    verdict or solution of the Teacher in charge, then the same should be placed before the Department 
    Head. The members can avail the help of grievance cell if necessary. If the issue still remains 
    unsolved , it can be forwarded to Principal. The Principal will redress all issues in consultation 
    with college council and director.
    <sub-body subTitle="Cell Members">
        <span class="box-title2">Chairman</span><br/>
        <ul class="outer-ul">
            <li><span class="bold">Fr. James Devasia CMI, M.Tech.</span> - Principal</li>
        </ul>
        <span class="box-title2">Executive Members</span><br/>
        <ul class="outer-ul">
            <li><span class="bold">Fr. Jacob Kurian CMI, M.Tech.</span> - HOD, Electrical and Electronics Engineering</li>
            <li><span class="bold">Mr. Rajesh R., M.Tech.</span> - HOD, Mechanical Engineering</li>
            <li><span class="bold">Mr. Jonu Joseph, D.A.E., A.M.I.E.</span> - HOD, Automobile Engineering</li>
            <li><span class="bold">Mrs. Deepa Nair, M.E.</span> - HOD, Computer Engineering</li>
            <li><span class="bold">Mrs. Jisha Agnes Jose, D.E.L., A.M.I.E.</span> - HOD, Electronics Engineering</li>
        </ul>
    </sub-body>
</main-body>
<main-body mainTitle="Anti-Ragging Cell" *ngIf="route === '/anti-ragging-cell'">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_anti_ragging_cell" type="pre_banner_sub"></preload-picture>
    </div>
    Ragging is a crime. Those found indulging in ragging activitites shall be punished as per the existing law 
    of AICTE and THE KERALA PROHIBITION OF RAGGING ACT, 1998.<br/><br/>

    An Anti- Ragging cell is constituted at the college level. Any act of ragging (Both in college and hostel) 
    shall be immediately brought to notice of teachers / staff / anti-ragging committee/hostel wardens. The 
    Anti-Ragging committee is constituted with the Principal as the Chairman and HODs of all six branches as 
    Executive Members.
    <sub-body subTitle="Cell Members">
        <span class="box-title2">Chairman</span><br/>
        <ul class="outer-ul">
            <li><span class="bold">Fr. James Devasia CMI, M.Tech.</span> - Principal</li>
        </ul>
        <span class="box-title2">Members</span><br/>
        <ul class="outer-ul">
            <li><span class="bold">Fr. Jacob Kurian CMI, M.Tech.</span> - HOD, Electrical and Electronics Engineering</li>
            <li><span class="bold">Mr. Rajesh R., M.Tech.</span> - HOD, Mechanical Engineering</li>
            <li><span class="bold">Mr. Jonu Joseph, D.A.E., A.M.I.E.</span> - HOD, Automobile Engineering</li>
            <li><span class="bold">Mrs. Deepa Nair, M.E.</span> - HOD, Computer Engineering</li>
            <li><span class="bold">Mrs. Jisha Agnes Jose, D.E.L., A.M.I.E.</span> - HOD, Electronics Engineering</li>
            <li><span class="bold">Mr. Lijomon Mathew, I.T.I.</span></li>
            <li><span class="bold">Mrs. R. Rejani, D.E.E.E.</span></li>
            <li><span class="bold">Mr. Rajesh G.</span> - P.T.A.</li>
            <li><span class="bold">Mr. Vinod Kumar B.</span> - Superintendent of Police</li>
            <li><span class="bold">Mrs. Sudharma</span> - Society</li>
        </ul>
    </sub-body>
    <sub-body subTitle="Links for Reference">
        <ul class="links-ul">
            <li><a href="/assets/documents/anti-ragging.pdf" target="_blank">Prevention and prohibition of Ragging in technical Institutions, Universities including Deemed to be Universities imparting technical education.-F.No.37-3/Legal/AICTE/2009.</a></li>
            <li><a href="/assets/documents/kpra.pdf" target="_blank">THE KERALA PROHIBITION OF RAGGING ACT, 1998.</a></li>
            <li><a href="/assets/documents/notice-ragging.pdf" target="_blank">UNIVERSITY GRANTS COMMISSION-No. F. 1‐21/2009. (Anti Ragging)</a></li>
        </ul>
    </sub-body>
</main-body>
<main-body mainTitle="Extension of Approvals" *ngIf="route === '/extension-of-approvals'">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_extension_of_approvals" type="pre_banner_sub"></preload-picture>
    </div>
    <sub-body subTitle="Approvals">
        <div class="row eoa-row">
            <div class="col-6 col-sm-4">
                <ul class="links-ul with-bullet">
                    <li><a href="/assets/documents/eoa-report-2023-2024.pdf" target="_blank">2023 - 2024</a></li>
                    <li><a href="/assets/documents/eoa-report-2022-2023.pdf" target="_blank">2022 - 2023</a></li>
                    <li><a href="/assets/documents/eoa-report-2021-2022.pdf" target="_blank">2021 - 2022</a></li>
                    <li><a href="/assets/documents/eoa-report-2020-2021.pdf" target="_blank">2020 - 2021</a></li>
                    <li><a href="/assets/documents/eoa-report-2019-2020.pdf" target="_blank">2019 - 2020</a></li>
                    <li><a href="/assets/documents/eoa-report-2018-2019.pdf" target="_blank">2018 - 2019</a></li>
                    <li><a href="/assets/documents/eoa-report-2017-2018.pdf" target="_blank">2017 - 2018</a></li>
                    <li><a href="/assets/documents/eoa-report-2016-2017.pdf" target="_blank">2016 - 2017</a></li>
                    <li><a href="/assets/documents/eoa-report-2015-2016.pdf" target="_blank">2015 - 2016</a></li>
                </ul>
            </div>
            <div class="col-6 col-sm-4">
                <ul class="links-ul with-bullet">
                    <li><a href="/assets/documents/eoa-report-2014-2015.pdf" target="_blank">2014 - 2015</a></li>
                    <li><a href="/assets/documents/eoa-report-2013-2014.pdf" target="_blank">2013 - 2014</a></li>
                    <li><a href="/assets/documents/eoa-report-2012-2013.pdf" target="_blank">2012 - 2013</a></li>
                    <li><a href="/assets/documents/eoa-report-2011-2012.pdf" target="_blank">2011 - 2012</a></li>
                    <li><a href="/assets/documents/eoa-report-2010-2013.pdf" target="_blank">2010 - 2013</a></li>
                    <li><a href="/assets/documents/eoa-report-2007-2010.pdf" target="_blank">2007 - 2010</a></li>
                    <li><a href="/assets/documents/eoa-report-2006-2007.pdf" target="_blank">2006 - 2007</a></li>
                    <li><a href="/assets/documents/eoa-report-2003-2006.pdf" target="_blank">2003 - 2006</a></li>
                </ul>
            </div>
        </div>
    </sub-body>
</main-body>
<main-body mainTitle="Internal Quality Assurance Cell (IQAC)" *ngIf="route === '/internal-quality-assurance-cell'">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_internal_quality_assurance_cell" type="pre_banner_sub"></preload-picture>
    </div>
    The internal quality assurance mechanism of the institution “Internal Quality Assurance Cell (IQAC)” is 
    meant for planning, guiding and monitoring Quality Assurance (QA) and Quality Enhancement (QE) activities 
    of the institution. Since quality enhancement is a continuous process, the IQAC becomes a part of the 
    institution's system and works towards realization of the goals of quality enhancement and sustenance.<br/><br/>
    <span class="box-title2">Objectives of IQAC</span><br/>
    <ul class="outer-ul">
        <li>To develop a system for conscious, consistent and catalytic action to improve the academic and 
            administrative performance of the institution.</li>
        <li>To promote measures for institutional functioning towards quality enhancement through internalization 
            of quality culture and institutionalization of best practices.</li>
    </ul>
    <sub-body subTitle="Cell Members">
        <span class="box-title2">Co-ordinator</span><br/>
        <ul class="outer-ul">
            <li><span class="bold">Mrs. Rakhi V.R., M.Sc. Chemistry, B.Ed.</span></li>
        </ul>
        <span class="box-title2">Members</span><br/>
        <ul class="outer-ul">
            <li><span class="bold">Fr. Bijo M.D. CMI, M.Tech.</span></li>
            <li><span class="bold">Mr. Aneesh U., B.Tech.</span></li>
            <li><span class="bold">Mrs. Sreeji Srinivas, M.Tech.</span></li>
            <li><span class="bold">Mrs. Tinu Scaria, M.Tech.</span></li>
            <li><span class="bold">Mrs. Rincy Joseph C.T., M.Tech.</span></li>
            <li><span class="bold">Mr. Vineeth Mathew, M.Tech.</span></li>
        </ul>
    </sub-body>
</main-body>
<main-body mainTitle="Internal Complaints Committee (ICC)" *ngIf="route === '/internal-complaints-committee'">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_internal_complaints_committee" type="pre_banner_sub"></preload-picture>
    </div>
    ICC meets on a need basis to address any complaints from students, teaching and nonteaching members and takes 
    necessary action. ICC is committed to provide safe and conducive academic and work environment to students and 
    its employees and is extremely alert to matter pertaining to any kind of harassment and gender sensitivity.<br/><br/>
    <sub-body subTitle="Committee Members">
        <span class="box-title2">Convenor</span><br/>
        <ul class="outer-ul">
            <li><span class="bold">Mrs. Jessy Chiramel, B.Tech.</span></li>
        </ul>
        <span class="box-title2">Members</span><br/>
        <ul class="outer-ul">
            <li><span class="bold">Fr. Siji Thomas P.T. CMI, M.E.</span></li>
            <li><span class="bold">Mrs. Ans Mathew, M.Sc., B.Ed.</span></li>
            <li><span class="bold">Mrs. Amalu Mariya Joseph, B.Tech.</span></li>
            <li><span class="bold">Mrs. Meenu M., D.C.E.</span></li>
        </ul>
    </sub-body>
</main-body>
<main-body mainTitle="SC/ST Monitoring Committee" *ngIf="route === '/sc-st-monitoring-committee'">
    <div class="sub-banner">
        <preload-picture folder="/assets/images" fileName="banner_sc_st_monitoring_committee" type="pre_banner_sub"></preload-picture>
    </div>
    The responsibility of the SC/ST Monitoring Committee is to function as a Grievances Redressal Cell for the 
    grievances of SC/ST students and employees and render them necessary help in solving their academic as well 
    as administrative problems.<br/><br/>

    As per AICTE guidelines, a committee is formed for prevention of atrocities against SC/ST students under the 
    Act No. 33 of the Scheduled Castes and the Scheduled Tribes (Prevention of Atrocities) Act, 1989. In case of 
    any grievance in this regard, students can approach the committee for redressal. The committee will suggest 
    measures to prevent atrocities, if any against any of the SC/ST students in the institute and to ensure the 
    feeling of security amongst them as per the provisions in the said act.
    <sub-body subTitle="Committee Members">
        <span class="box-title2">Chairman</span><br/>
        <ul class="outer-ul">
            <li><span class="bold">Fr. James Devasia CMI, M.Tech</span> - Principal</li>
        </ul>
        <span class="box-title2">Members</span><br/>
        <ul class="outer-ul">
            <li><span class="bold">Mr. Jonu Joseph, D.A.E., A.M.I.E.</span> - HOD, Automobile Engineering</li>
            <li><span class="bold">Mr. K.S. Venugopal, B.A., D.E.E.E.</span></li>
            <li><span class="bold">Mrs. K.S. Bindhu, M.Sc., B.Ed., M.S.W.</span></li>
            <li><span class="bold">Mrs. Sowmya Xavier, B.Tech.</span></li>
            <li><span class="bold">Mr. B. Hareesh, B.Tech.</span></li>
            <li><span class="bold">Mr. Ramachandran G.</span> - J.C.T.E, D.T.E.</li>
        </ul>
    </sub-body>
</main-body>