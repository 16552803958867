<message-box *ngIf="error" [message]="error" type="Error"></message-box>
<div *ngIf="!error">
    <sub-body *ngFor="let activity of activities" [subTitle]="activity.name">
        <div *ngIf="activity.description">{{activity.description}}</div>
        <div *ngIf="activity?.members?.length > 0" class="box-title2">Executive Members</div>
        <ul *ngIf="activity?.members?.length > 0" class="outer-ul">
            <li *ngFor="let member of activity.members"><span class="bold">{{member.designation}}</span>: {{member.name}}</li>
        </ul>
    </sub-body>
</div>
