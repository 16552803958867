<footer>
    <div class="row outer-row footer-top">
        <div class="col-xs-12 col-sm-9 footer-top-col">
            <h6><fa name="chain"></fa>Quick Links</h6>
            <div class="row qlinks-row">
                <div class="col-xs-6 col-sm-6 qlinks-col">
                    <ul>
                        <li>
                            <a [routerLink]="['/about-us']">About Us</a>
                        </li>
                        <li>
                            <a [routerLink]="['/grievance-redressal-cell']">Grievance Redressal Cell</a>
                        </li>
                        <li>
                            <a [routerLink]="['/anti-ragging-cell']">Anti-Ragging Cell</a>
                        </li>
                        <li>
                            <a [routerLink]="['/internal-quality-assurance-cell']">Internal Quality Assurance Cell (IQAC)</a>
                        </li>
                        <li>
                            <a [routerLink]="['/internal-complaints-committee']">Internal Complaints Committee (ICC)</a>
                        </li>
                        <li>
                            <a [routerLink]="['/sc-st-monitoring-committee']">SC/ST Monitoring Committee</a>
                        </li>
                    </ul>
                </div>
                <div class="col-xs-6 col-sm-6 qlinks-col">
                    <ul>
                        <li>
                            <a href="/assets/documents/mandatory-disclosure-2023-2024.pdf" target="_blank">Mandatory Disclosure</a>
                        </li>
                        <li>
                            <a [routerLink]="['/extension-of-approvals']">Extension of Approvals</a>
                        </li>
                        <li>
                            <a href="https://www.aicte-india.org/feedback" target="_blank">AICTE Feedback for Students & Faculties</a>
                        </li>
                        <li>
                            <a href="http://www.carmelcet.in" target="_blank">Carmel College of Engineering & Technology</a>
                        </li>
                        <li>
                            <a href="https://forms.gle/yJRff7y1hptdck3d8" target="_blank">Admission Enquiry</a>
                        </li>
                        <li>
                            <a [routerLink]="['/contact-us']">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-3 footer-top-col">
            <h6><fa name="map-marker"></fa>Contact Address</h6>
            <ul class="footer-address">
                <li>
                    <span class="bold">Carmel Polytechnic College</span><br/>
                    Punnapra, Alappuzha - 688 004, Kerala, India.
                </li>
                <li>
                    Phone: 0477 2287 825 (Office)
                </li>
                <li>
                    Phone: 0477 2288 825 (Self Financing)
                </li>
                <li>
                    Phone: 80783 25976 (Admission Cell)
                </li>
                <li>
                    Fax: 0477 2286 048 (Fax)
                </li>
                <li>
                    Email: info@carmelpoly.in
                </li>
            </ul>
        </div>
    </div>
    <div class="row outer-row footer-bottom">
        <div class="col-xs-12 col-sm-6 footer-bottom-col">
            <span>Copyright © 2023 Carmel Polytechnic | All Rights Reserved.</span>
        </div>
        <div class="col-xs-12 col-sm-6 footer-bottom-col right">
            <span>Designed, developed & maintained by Libu T. Babu.</span>
        </div>
    </div>
</footer>