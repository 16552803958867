<div class="social-outer" [@slideInOut]="position">
    <div class="btn-wrapper">
        <div class="switch-btn" [ngClass]="{rotate: position === 'out'}" (click)="slide($event)">
            <fa name="angle-right"></fa>
        </div>
    </div>
    <div class="link-wrapper">
        <a href="https://www.facebook.com/groups/carmelpolytechnic" target="_blank" class="facebook">
            <fa name="facebook"></fa>
        </a>
        <a href="https://www.youtube.com/channel/UCDHxGe9ioZIQ2NS40iqbo1g" target="_blank" class="youtube">
            <fa name="youtube"></fa>
        </a>
        <a href="https://www.twitter.com/carmelptc" target="_blank" class="twitter">
            <fa name="twitter"></fa>
        </a>
        <a class="instagram">
            <fa name="instagram"></fa>
        </a>
    </div>
</div>