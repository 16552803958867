import { Routes } from '@angular/router';

import { ContactUsComponent } from '@app/contact-us-module/contact-us/contact-us.component';

export const ContactUsRoutes: Routes = [
  {
    path: '',
    component: ContactUsComponent,
    data: {
      title: 'Contact Us'
    }
  }
];
