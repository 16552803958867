import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { DepartmentRoutes } from './department.routing';
import { DepartmentsTabPanelComponent, ProgrammesComponent, ActivitiesComponent, FacultyComponent, LabsComponent, SyllabusComponent } from './departments-tab-panel';


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    SharedModule,
    RouterModule.forChild(DepartmentRoutes)
  ],
  declarations: [
    DepartmentsTabPanelComponent,
    ProgrammesComponent,
    ActivitiesComponent,
    FacultyComponent,
    LabsComponent,
    SyllabusComponent
  ]
})
export class DepartmentModule {}
